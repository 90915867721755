import get from 'lodash/get';

export const IS_LOCAL = window.location.hostname === "localhost";
export const BACKEND_URL = IS_LOCAL ? "http://localhost:5002/" : "https://www.swolehalla.com/";
// export const BACKEND_URL = "https://www.swolehalla.com/";
export const WEBSITE_URL = "https://www.swolehalla.com/";

export const TOP_ONE_PERCENT_MALE = "Brodin";
export const TOP_FIVE_PERCENT_MALE = "Chadmiral";
export const TOP_TEN_PERCENT_MALE = "Swolemmando";
export const TOP_TWENTY_FIVE_PERCENT_MALE = "Swoledier";
export const TOP_FIFTY_PERCENT_MALE = "Brethren of the Iron Temple";
export const TOP_SEVENTY_FIVE_PERCENT_MALE = "Aspiring swoledier";
export const TOP_ONE_HUNDRED_PERCENT_MALE = "Aspiring Brethren of the Iron Temple";

export const TOP_ONE_PERCENT_FEMALE = "Valkyrie";
export const TOP_FIVE_PERCENT_FEMALE = "Shield Maiden";
export const TOP_TEN_PERCENT_FEMALE = "Swolemmando";
export const TOP_TWENTY_FIVE_PERCENT_FEMALE = "Swoledier";
export const TOP_FIFTY_PERCENT_FEMALE = "Sister of the Iron Temple";
export const TOP_SEVENTY_FIVE_PERCENT_FEMALE = "Aspiring swoledier";
export const TOP_ONE_HUNDRED_PERCENT_FEMALE = "Aspiring Sister of the Iron Temple";

export interface IUserValues {
    userWorkoutValues: ICurrentWorkout[];
    overallAverage: string;
    gender: string;
    weight: string;
    age: string;
}

export interface IWorkoutResults {
    exercisePercentages: ICurrentWorkout[];
    exerciseType: string;
    average: number;
}

export interface IConvertedUserWorkoutValuesDto {
    userWorkoutValuesDtos: ICurrentWorkout[];
    weight: string;
}

export interface IBenchmark {
    colorCode: string;
    name: string;
    reps: number;
    percentage: number;
    title?: string;
    value: number;
    workoutName: string;
    duplicatedValueCount: number;
  }

export interface ICurrentWorkout {
    reps?: string;
    fiftyPercentageValue?: string;
    percentage?: string;
    workoutName: string;
    workoutValue: string;
}

export interface ICurrentWorkouts {
    [key: string]: ICurrentWorkout
}

export interface ISignupValues {
    email: string;
    password: string;
    wantsEmail: boolean;
    userValues: IUserValues;
    captchaResponse: string;
}

export interface IUserValues {
    overallAverage: string;
    metric: boolean;
    gender: string;
    weight: string;
    age: string;
    userWorkoutValues: ICurrentWorkout[];
}

export interface IResultsArray {
    imageSrc: string;
    name: string;
    topPercentage: string;
}

export interface IWorkoutDictionaryValues {
    formUrl?: string;
    name: string;
    max: string;
    measurement: string;
    setValue?: any;
    setReps?: any;
    supportsReps?: boolean;
    reps?: string;
    value?: string;
    workoutGroup: string;
    workoutIconUrl: string;
}

export interface IWorkoutDictionary {
    [name: string] : IWorkoutDictionaryValues;
}

export const allWorkoutsDictionary: IWorkoutDictionary = {
    // CARDIO WORKOUTS
    Jumping_Jacks: {
        formUrl: "https://www.bodybuilding.com/exercises/jumping-jacks",
        name: "Jumping Jacks (Two Mins)",
        max: "9999",
        measurement: "count",
        workoutGroup: "Cardio",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Jumping_Jacks.png"
    },
    One_Mile_Run: {
        formUrl: "https://www.verywellfit.com/learn-to-run-continuously-2911965",
        name: "One Mile Run (1.6 Km)",
        max: "30",
        measurement: "seconds",
        workoutGroup: "Cardio",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/One_Mile_Run.png"
    },
    Five_K: {
        formUrl: "https://www.runnersworld.com/races-places/a20855451/how-many-miles-is-a-5k/",
        name: "5K (3.1 Miles)",
        max: "60",
        measurement: "seconds",
        workoutGroup: "Cardio",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/5k.png"
    },
    Half_Marathon: {
        formUrl: "https://www.runnersworld.com/uk/training/half-marathon/a28784383/good-half-marathon-time/",
        name: "Half Marathon",
        max: "10",
        measurement: "minutes",
        workoutGroup: "Cardio",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Half_Marathon.png"
    },

    // UPPER BODY WORKOUTS
    Push_Ups: {
        formUrl: "https://www.bodybuilding.com/exercises/pushups",
        name: "Consecutive Push Ups",
        max: "9999",
        measurement: "count",
        workoutGroup: "Upper_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Push_Ups.png",
    },
    Pull_Ups: {
        formUrl: "https://www.bodybuilding.com/exercises/pullups",
        name: "Pull Ups",
        max: "9999",
        measurement: "count",
        workoutGroup: "Upper_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Pull_Ups.png",
    },
    Bench_Press: {
        formUrl: "https://www.bodybuilding.com/exercises/barbell-bench-press-medium-grip",
        name: "Bench Press",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Upper_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Bench_Press.png",
    },
    Dumbbell_Curl: {
        formUrl: "https://www.bodybuilding.com/exercises/dumbbell-bicep-curl",
        name: "Dumbbell Curl",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Upper_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Dumbbell_Curl.png",
    },
    Shoulder_Press: {
        formUrl: "https://www.bodybuilding.com/exercises/barbell-shoulder-press",
        name: "Shoulder Press",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Upper_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Shoulder_Press.png",
    },
    Power_Clean: {
        formUrl: "https://www.bodybuilding.com/exercises/power-clean",
        name: "Power Clean",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Upper_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Power_Clean.png",
    },

    // LOWER BODY WORKOUTS
    Squat: {
        formUrl: "https://www.bodybuilding.com/exercises/barbell-squat",
        name: "Barbell Squat",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Lower_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Barbell_Squat.png",
    },
    Lunge: {
        formUrl: "https://www.bodybuilding.com/exercises/dumbbell-lunges",
        name: "Dumbbell Lunge",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Lower_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Barbell_Lunge.png",
    },
    Leg_Press: {
        formUrl: "https://www.bodybuilding.com/exercises/leg-press",
        name: "Leg Press",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Lower_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Leg_Press.png",
    },
    Dead_Lift: {
        formUrl: "https://www.bodybuilding.com/exercises/barbell-deadlift",
        name: "Dead Lift",
        max: "9999",
        measurement: "lbs",
        supportsReps: true,
        workoutGroup: "Lower_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Dead_Lift.png",
    },
    Bodyweight_Squat: {
        formUrl: "https://www.bodybuilding.com/exercises/bodyweight-squat",
        name: "Bodyweight Squat (Two Mins)",
        max: "9999",
        measurement: "count",
        workoutGroup: "Lower_Body",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Bodyweight_Squat.png",
    },

    // CORE WORKOUT QUESTIONS
    Sit_Ups: {
        formUrl: "https://www.bodybuilding.com/exercises/sit-up",
        name: "Consecutive Sit Ups",
        max: "9999",
        measurement: "count",
        workoutGroup: "Core",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Sit_Ups.png",
    },
    Planks: {
        formUrl: "https://www.bodybuilding.com/exercises/plank",
        name: "Planks",
        max: "30",
        measurement: "seconds",
        workoutGroup: "Core",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Plank.png",
    },
    Bicycles: {
        formUrl: "https://www.bodybuilding.com/exercises/air-bike",
        name: "Consecutive Bicycles",
        max: "99999",
        measurement: "count",
        workoutGroup: "Core",
        workoutIconUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/workouts/Bicycles.png",
    },
};

export const createWorkoutObject = (workoutName: string, value: string, setValue: React.Dispatch<React.SetStateAction<string>>, reps: string, setReps: React.Dispatch<React.SetStateAction<string>> | null, metric: boolean): IWorkoutDictionaryValues => {
    const workoutDictionaryEntry: IWorkoutDictionaryValues = Object.create(allWorkoutsDictionary[workoutName]);
    if (workoutDictionaryEntry.supportsReps) {
        workoutDictionaryEntry.setReps = setReps;
        workoutDictionaryEntry.reps = reps;
    }
    workoutDictionaryEntry.setValue = setValue;
    workoutDictionaryEntry.value = isNaN(parseInt(value)) ? '' : value;
    workoutDictionaryEntry.measurement = getMeasurementNameFromWorkoutName(workoutName, metric);
    return workoutDictionaryEntry;
}

export const getMeasurementNameFromWorkoutName = (workoutName: string, metric: boolean): string => {
    const workoutDictionaryEntry = allWorkoutsDictionary[workoutName];
    return workoutDictionaryEntry.measurement === "lbs" ? 
        (metric ? "kg" : "lbs") : workoutDictionaryEntry.measurement;
}

export const maleResultsArray: IResultsArray[] = [
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newMale/01_resized.png",
        name: TOP_ONE_PERCENT_MALE,
        topPercentage: "Top 1%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newMale/02_resized.png",
        name: TOP_FIVE_PERCENT_MALE,
        topPercentage: "Top 5%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newMale/03_resized.png",
        name: TOP_TEN_PERCENT_MALE,
        topPercentage: "Top 10%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newMale/04_resized.png",
        name: TOP_TWENTY_FIVE_PERCENT_MALE,
        topPercentage: "Top 25%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newMale/05_resized.png",
        name: TOP_FIFTY_PERCENT_MALE,
        topPercentage: "Top 50%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newMale/06_resized.png",
        name: TOP_SEVENTY_FIVE_PERCENT_MALE,
        topPercentage: "Bottom 50%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newMale/07_resized.png",
        name: TOP_ONE_HUNDRED_PERCENT_MALE,
        topPercentage: "Bottom 25%",
    },
];

export const femaleResultsArray: IResultsArray[] = [
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newFemale/01_resized.png",
        name: TOP_ONE_PERCENT_FEMALE,
        topPercentage: "Top 1%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newFemale/02_resized.png",
        name: TOP_FIVE_PERCENT_FEMALE,
        topPercentage: "Top 5%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newFemale/03_resized.png",
        name: TOP_TEN_PERCENT_FEMALE,
        topPercentage: "Top 10%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newFemale/04_resized.png",
        name: TOP_TWENTY_FIVE_PERCENT_FEMALE,
        topPercentage: "Top 25%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newFemale/05_resized.png",
        name: TOP_FIFTY_PERCENT_FEMALE,
        topPercentage: "Top 50%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newFemale/06_resized.png",
        name: TOP_SEVENTY_FIVE_PERCENT_FEMALE,
        topPercentage: "Bottom 50%",
    },
    {
        imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/rankings/newFemale/07_resized.png",
        name: TOP_ONE_HUNDRED_PERCENT_FEMALE,
        topPercentage: "Bottom 25%",
    },
];

export const calculateSwolehallaRanking = (gender: string, percentage: number): IResultsArray => {
    if (gender === "Male") {
        if (percentage <= 1) {
            return maleResultsArray[0];
        } else if (percentage <= 5) {
            return maleResultsArray[1];
        } else if (percentage <= 10) {
            return maleResultsArray[2];
        } else if (percentage <= 25) {
            return maleResultsArray[3];
        } else if (percentage <= 50) {
            return maleResultsArray[4];
        } else if (percentage <= 75) {
            return maleResultsArray[5];
        } else {
            return maleResultsArray[6];
        }
    } else {
        if (percentage <= 1) {
            return femaleResultsArray[0];
        } else if (percentage <= 5) {
            return femaleResultsArray[1];
        } else if (percentage <= 10) {
            return femaleResultsArray[2];
        } else if (percentage <= 25) {
            return femaleResultsArray[3];
        } else if (percentage <= 50) {
            return femaleResultsArray[4];
        } else if (percentage <= 75) {
            return femaleResultsArray[5];
        } else {
            return femaleResultsArray[6];
        }
    }
};

export const updateWorkouts = (workoutsToUpdate: ICurrentWorkout[]) => {
    const workouts: ICurrentWorkouts = JSON.parse(localStorage.getItem('workouts') || '{}') || {};
    workoutsToUpdate.forEach((workout: ICurrentWorkout) => {
        workouts[workout.workoutName] = workout;
        workouts[workout.workoutName].workoutValue = isNaN(parseInt(workout.workoutValue)) ? '' : workout.workoutValue;
        workouts[workout.workoutName].fiftyPercentageValue = isNaN(parseInt(workout.workoutValue)) ? '' : workout.fiftyPercentageValue;
    });

    localStorage.setItem("workouts", JSON.stringify(workouts));
}

export interface ISetCurrentWorkouts {
    setFunction: any;
    setRepsFunction?: any;
    workoutName: string;
}

export const setCurrentWorkouts = (workoutsToSet: ISetCurrentWorkouts[]) => {
    const workouts: ICurrentWorkouts = JSON.parse(localStorage.getItem('workouts') || '{}') || {};
    workoutsToSet.forEach((workout: ISetCurrentWorkouts) => {
        const currentWorkout: ICurrentWorkout = get(workouts, workout['workoutName']);
        workout.setFunction((get(currentWorkout, 'workoutValue') || "").toString());
        if (workout.setRepsFunction) {
            workout.setRepsFunction((get(currentWorkout, 'reps') || "1").toString());
        }
    });
}
