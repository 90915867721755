import React from 'react';
import TakeHomeWorkoutQuestionPage from '../take-home-workout-question-page';
import { createWorkoutObject, ICurrentWorkout, ISetCurrentWorkouts, IWorkoutDictionaryValues, setCurrentWorkouts, updateWorkouts } from '../../../../../constants';

const TakeHomeUpperBodyQuestions = (isMobile: boolean, nextQuestionFunction: () => void): JSX.Element => {
  const [pushUps, setPushUps] = React.useState("");

  React.useEffect(() => {
    const workoutsToSet: ISetCurrentWorkouts[] = [
      {setFunction: setPushUps, workoutName: "Push_Ups"},
    ];
    setCurrentWorkouts(workoutsToSet);
  }, []);

  React.useEffect(() => {
    const workoutsToUpdate: ICurrentWorkout[] = [
      {workoutName: "Push_Ups", workoutValue: pushUps},
    ];
    updateWorkouts(workoutsToUpdate);
  }, [pushUps]);

  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");

  const upperBodyQuestionsArray: IWorkoutDictionaryValues[] = [
    createWorkoutObject("Push_Ups", pushUps, setPushUps, "1", null, metric),
  ];

  return (
    <TakeHomeWorkoutQuestionPage
      isMobile={isMobile}
      nextQuestionFunction={nextQuestionFunction}
      workoutQuestions={upperBodyQuestionsArray} 
    />
  );
}

export default TakeHomeUpperBodyQuestions;
