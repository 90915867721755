import React, { FC } from 'react';
import classNames from 'classnames';
import NumberInput from 'components/number-input';
import SliderBar from 'components/slider-bar';
import StyledButton from 'components/styled-button';
import StyledText from 'components/styled-text';
import QuestionMarkHover from 'components/question-mark-hover';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import './intro-questions.scss';

const STYLE_BASE = "INTRO_QUESTIONS-";

export const AGE_EXPLANATION: string = "Age is but an illusion in the iron fields of Swolehalla. But before ascending it must be used to properly compare you to your peers";
export const WEIGHT_EXPLANATION: string = "Extra weight is a blessing and a curse in thy journey to Swolehalla. It will be used to properly compare you to your peers";
export const GENDER_EXPLANATION: string = "Gender is but a mortal concept. But before ascending it shall be used to properly compare you to your peers";

export const INPUT_WORKOUTS_NAME: string = "Enter Your Lifts";
export const INPUT_WORKOUTS_QUESTION: string = "Already know your workout counts?";
export const INPUT_WORKOUTS_EXPLANATION: string = "Input your workouts and be ranked among Swolehalla";
export const TAKE_HOME_TEST_NAME: string = "Take Home Test";
export const TAKE_HOME_TEST_QUESTION: string = "Don't know your workouts offhand?";
export const TAKE_HOME_TEST_EXPLANATION: string = "Take the at-home workout test and determine your rank";

export const INPUT_WORKOUT_QUESTION_HOVER: FC = () => 
  <QuestionMarkHover 
    text={INPUT_WORKOUTS_QUESTION}
    textTwo={INPUT_WORKOUTS_EXPLANATION}
  />;
export const TAKE_HOME_TEST_QUESTION_HOVER: FC = () => 
  <QuestionMarkHover 
    text={TAKE_HOME_TEST_QUESTION}
    textTwo={TAKE_HOME_TEST_EXPLANATION}
  />;

export const setAgeValue = (value: any, setAge: (age: any) => void) => {
  let curVal = value.target.value || '';
  if (!curVal) {
    curVal = '';
  } else if (parseInt(curVal, 10) >= 100) {
    curVal = '100';
  } else if (parseInt(curVal, 10) < 1) {
    curVal = '1';
  }
  setAge(curVal);
}

const IntroQuestions = (): JSX.Element => {
  const [metric, setMetric] = React.useState(false);
  const [weight, setWeight] = React.useState(localStorage.getItem("weight") || "125");
  const [age, setAge] = React.useState("25");
  const [gender, setGender] = React.useState("Female");
  const [trialType, setTrialType] = React.useState(INPUT_WORKOUTS_NAME);

  React.useEffect(() => {
    setWeight(localStorage.getItem("weight") || "125");
    setAge(localStorage.getItem("age") || "25");
    setGender(localStorage.getItem("gender") || "Female");
    setMetric(Boolean(localStorage.getItem("metric") === "true"));
  }, []);

  React.useEffect(() => {
    localStorage.setItem("age", age);
  }, [age]);

  React.useEffect(() => {
    localStorage.setItem("gender", gender);
  }, [gender]);

  React.useEffect(() => {
    localStorage.setItem("weight", weight);
  }, [weight]);

  React.useEffect(() => {
    localStorage.setItem("metric", metric.toString());
    setWeight(localStorage.getItem("weight") || "125");
  }, [metric]);

  React.useEffect(() => {
    localStorage.setItem("trialType", trialType);
  }, [trialType]);

  const {
    convertMeasurementUserValues,
  } = useQuizContext() as IQuizContextValues;

  return (
    <div className={`${STYLE_BASE}container`}>
      <div className={`${STYLE_BASE}questionsContainer`}>
        <div className={`${STYLE_BASE}questionRow`}>
          <div className={`${STYLE_BASE}questionSection`}>
            <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Age</StyledText>
          </div>
          <div className={`${STYLE_BASE}answerSection`}>
            <input
              className={classNames(`${STYLE_BASE}ageNumberInput`, "textSize")}
              onChange={(value) => setAgeValue(value, setAge)}
              type="number"
              value={age}
            />
            <SliderBar className={`${STYLE_BASE}sliderBar`} setValue={setAge} value={age} />
          </div>
        </div>

        <div className={`${STYLE_BASE}questionRow`}>
          <div className={`${STYLE_BASE}questionSection`}>
            <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Gender</StyledText>
          </div>
          <div className={`${STYLE_BASE}answerSection`}>
            <StyledButton
              className={classNames(`${STYLE_BASE}genderButton`, gender === "Female" ? `${STYLE_BASE}selectedButton` : '')}
              onClick={() => setGender("Female")}
              type="smallButton"
            >
              Female
            </StyledButton>
            <StyledButton
              className={classNames(`${STYLE_BASE}genderButton`, gender === "Male" ? `${STYLE_BASE}selectedButton` : '')}
              onClick={() => setGender("Male")}
              type="smallButton"
            >
              Male
            </StyledButton>
            <StyledButton
              className={classNames(`${STYLE_BASE}genderButton`, gender === "Non-binary" ? `${STYLE_BASE}selectedButton` : '')}
              onClick={() => setGender("Non-binary")}
              type="smallButton"
            >
              Non-binary
            </StyledButton>
          </div>
        </div>

        <div className={`${STYLE_BASE}questionRow`}>
          <div className={`${STYLE_BASE}questionSection`}>
            <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Weight</StyledText>
          </div>
          <div className={`${STYLE_BASE}answerSection`}>
            <div className={`${STYLE_BASE}numperInputAndMetricChoiceContainer`}>
              <NumberInput max="1400" measurement={metric ? 'kg' : 'lbs'} setValue={setWeight} value={weight} />
              <div className={`${STYLE_BASE}metricChoiceContainer`}>
                <StyledText 
                  bold={!metric} 
                  className={`${STYLE_BASE}us`}
                  onClickFunction={() => {
                    if (metric === true) {
                      convertMeasurementUserValues(() => setMetric(false));
                    }
                  }}
                  third={metric}
                  type="text"
                >
                  US
                </StyledText>
                <StyledText 
                  bold={metric} 
                  className={`${STYLE_BASE}metric`}
                  onClickFunction={() => {
                    if (metric === false) {
                      convertMeasurementUserValues(() => setMetric(true));
                    }
                  }}
                  third={!metric}
                  type="text"
                >
                  Metric
                </StyledText>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className={`${STYLE_BASE}questionsContainer`}>
        <div className={`${STYLE_BASE}questionRow`}>
          <div className={`${STYLE_BASE}questionSection`}>
            <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Trial Type</StyledText>
          </div>
          <div className={`${STYLE_BASE}answerSection`}>
            <div className={`${STYLE_BASE}trialTypeContainer`}>
              <StyledButton
                className={classNames(trialType === INPUT_WORKOUTS_NAME ? `${STYLE_BASE}selectedButton` : '')}
                onClick={() => setTrialType(INPUT_WORKOUTS_NAME)}
                type="bigButton"
                QuestionMarkHoverInButton={INPUT_WORKOUT_QUESTION_HOVER}
              >
                {INPUT_WORKOUTS_NAME}
              </StyledButton>
            </div>
            <div>
              <StyledButton
                className={classNames(trialType === TAKE_HOME_TEST_NAME ? `${STYLE_BASE}selectedButton` : '')}
                onClick={() => setTrialType(TAKE_HOME_TEST_NAME)}
                type="bigButton"
                QuestionMarkHoverInButton={TAKE_HOME_TEST_QUESTION_HOVER}
              >
                {TAKE_HOME_TEST_NAME}
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroQuestions;
