import React from 'react';
import QuizProvider from 'contexts/quizContext';
import AppRouter from '../app-router';
import AuthProvider from 'contexts/authContext';

const App = (): JSX.Element => {
  console.log("Your screen resolution is: " + window.screen.width + "x" + window.screen.height);
  return (
    <AuthProvider>
      <QuizProvider>
        <AppRouter/>
      </QuizProvider>
    </AuthProvider>
  );
}

export default App;
