import { MobileWorkoutQuestionPage } from 'containers/question-page/workout-question-page/workout-question-page';
import React from 'react';
import { createWorkoutObject, ICurrentWorkout, ISetCurrentWorkouts, IWorkoutDictionaryValues, setCurrentWorkouts, updateWorkouts } from '../../../../constants';
import WorkoutQuestionPage from '../../workout-question-page';

const LowerBodyQuestions = (isMobile: boolean): JSX.Element => {
  const [squat, setSquat] = React.useState("");
  const [squatReps, setSquatReps] = React.useState("1");
  const [lunge, setLunge] = React.useState("");
  const [lungeReps, setLungeReps] = React.useState("1");
  const [legPress, setLegPress] = React.useState("");
  const [legPressReps, setLegPressReps] = React.useState("1");
  const [deadLift, setDeadLift] = React.useState("");
  const [deadLiftReps, setDeadLiftReps] = React.useState("1");
  const [bodyweightSquat, setBodyweightSquat] = React.useState("");

  React.useEffect(() => {
    const workoutsToSet: ISetCurrentWorkouts[] = [
      {setFunction: setSquat, workoutName: "Squat", setRepsFunction: setSquatReps},
      {setFunction: setLunge, workoutName: "Lunge", setRepsFunction: setLungeReps},
      {setFunction: setLegPress, workoutName: "Leg_Press", setRepsFunction: setLegPressReps},
      {setFunction: setDeadLift, workoutName: "Dead_Lift", setRepsFunction: setDeadLiftReps},
      {setFunction: setBodyweightSquat, workoutName: "Bodyweight_Squat"},
    ];
    setCurrentWorkouts(workoutsToSet);
  }, []);

  React.useEffect(() => {
    const workoutsToUpdate: ICurrentWorkout[] = [
      {workoutName: "Squat", workoutValue: squat, reps: squatReps},
      {workoutName: "Lunge", workoutValue: lunge, reps: lungeReps},
      {workoutName: "Leg_Press", workoutValue: legPress, reps: legPressReps},
      {workoutName: "Dead_Lift", workoutValue: deadLift, reps: deadLiftReps},
      {workoutName: "Bodyweight_Squat", workoutValue: bodyweightSquat},
    ];
    updateWorkouts(workoutsToUpdate);
  }, [squat, squatReps, lunge, lungeReps, legPress, legPressReps, deadLift, deadLiftReps, bodyweightSquat]);

  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");

  const lowerBodyQuestionsArray: IWorkoutDictionaryValues[] = [
    createWorkoutObject("Squat", squat, setSquat, squatReps, setSquatReps, metric),
    createWorkoutObject("Lunge", lunge, setLunge, lungeReps, setLungeReps, metric),
    createWorkoutObject("Leg_Press", legPress, setLegPress, legPressReps, setLegPressReps, metric),
    createWorkoutObject("Dead_Lift", deadLift, setDeadLift, deadLiftReps, setDeadLiftReps, metric),
    createWorkoutObject("Bodyweight_Squat", bodyweightSquat, setBodyweightSquat, "1", null, metric),
  ];

  return isMobile ? 
    <MobileWorkoutQuestionPage workoutQuestions={lowerBodyQuestionsArray} /> 
    : <WorkoutQuestionPage workoutQuestions={lowerBodyQuestionsArray} />;
}

export default LowerBodyQuestions;
