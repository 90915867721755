import React from 'react';
import classNames from 'classnames';
import './slider-bar.scss';

const STYLE_BASE = "SLIDER_BAR";
const leftColor: string = "#464646";
const rightColor: string = '#E7E7E7';

interface ISliderBar {
  className?: string;
  setValue: (value: string) => void;
  type?: string;
  value: string;
}

const SliderBar = ({className, setValue, type, value}: ISliderBar): JSX.Element => {
  return (
    <input 
      className={classNames(`${STYLE_BASE}-container`, `${STYLE_BASE}-${type}`, className)} 
      min="1" 
      max="100"
      onChange={(e: React.FormEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
      // This is necessary to split the range into two colors
      style={{background: 'linear-gradient(to right, ' + leftColor + ' 0%, ' + leftColor + ' ' + value + '%, ' + rightColor + ' ' + value + '%, ' + rightColor + ' 100%)'}}
      type="range" 
      value={value}
    />
  );
}

export default SliderBar;
