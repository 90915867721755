import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import StyledText from 'components/styled-text';
import { allWorkoutsDictionary, ICurrentWorkout, ICurrentWorkouts, getMeasurementNameFromWorkoutName, IWorkoutDictionaryValues, IBenchmark } from '../../../constants';
import { snakeCaseToReadable } from '../../../util';
import './improve-summary.scss';
import WorkoutBreakdown from './workout-breakdown';
import StyledButton from 'components/styled-button';
import { Checkbox } from 'pretty-checkbox-react';
import QuestionMarkHover from 'components/question-mark-hover';

export const SHOW_BENCHMARK_TITLE: string = "Show strength benchmarks from various groups";
export const SHOW_BENCHMARK_TITLE_TWO: string = "See how your abilities stack up";
export const SHOW_BENCHMARK_TEXT: string = "Toggle benchmarks";
const STYLE_BASE = "IMPROVE_SUMMARY-";

export const getMuscleGroupWorkouts = (currentMuscleGroup: string): ICurrentWorkout[] => {
  const workouts: ICurrentWorkouts = JSON.parse(localStorage.getItem('workouts') || '{}') || {};
  const excercisesInWorkoutGroup: ICurrentWorkout[] = [];
  Object.values(workouts).forEach((workout: ICurrentWorkout) => {
    if (workout.percentage) {
      if (allWorkoutsDictionary[workout.workoutName].workoutGroup === currentMuscleGroup) {
        excercisesInWorkoutGroup.push(workout);
      }
    }
  });

  return excercisesInWorkoutGroup;
}

interface IImproveSummary {
  currentMuscleGroup: string;
  setCurrentMuscleGroup: any;
}
const ImproveSummary = ({currentMuscleGroup, setCurrentMuscleGroup}: IImproveSummary): JSX.Element => {
  const currentExcerciseTypeResults: ICurrentWorkout[] = getMuscleGroupWorkouts(currentMuscleGroup);
  const currentMuscleGroupImgSrc: string = getCurrentMuscleGroupImgSrc(currentMuscleGroup);

  const isDesktop: boolean = window.innerWidth >= 1600;
  const currentMuscleGroupImageHeight: string = isDesktop ? "345" : "230";
  const currentMuscleGroupImageWidth: string = isDesktop ? "225" : "150";

  return (
    <div className={`${STYLE_BASE}improveSummary`}>
      <div className={`${STYLE_BASE}statsBreakdownContainer`}>
        <StyledText
          className="largeMarginBottom"
          type="title"
        >
          Stats Breakdown
        </StyledText>
        <div className={`${STYLE_BASE}muscleGroupContainer`}>
          <div className={`${STYLE_BASE}muscleGroupButtonsContainer`}>
            {muscleGroupButtons.map((muscleGroup: IMuscleGroupButton) => {
              return (
                <StyledButton
                  className={`${STYLE_BASE}muscleGroupButton`}
                  onClick={() => {setCurrentMuscleGroup(muscleGroup.name)}}
                  type={currentMuscleGroup === muscleGroup.name ? "selected" : ""}
                  key={muscleGroup.name}
                >
                  {snakeCaseToReadable(muscleGroup.name)}
                </StyledButton>
              );
            })}
          </div>
          <img
            alt={currentMuscleGroup}
            className={`${STYLE_BASE}currentMuscleGroupImage`} 
            src={currentMuscleGroupImgSrc}
            width={currentMuscleGroupImageWidth}
            height={currentMuscleGroupImageHeight}
          />
        </div>
      </div>

      {(!!currentExcerciseTypeResults.length) ? 
        <WorkoutBreakdownSection 
          currentExcerciseTypeResults={currentExcerciseTypeResults}
          currentMuscleGroup={currentMuscleGroup}
        /> :
        <div className={`${STYLE_BASE}noWorkoutsContainer`}>
          <StyledText
              className="largeMarginBottom"
              type="smallTitle"
            >
              {`No workouts entered for ${snakeCaseToReadable(currentMuscleGroup)}.`}
          </StyledText>
          <StyledText
              className="largeMarginBottom"
              type="smallTitle"
            >
              {`Please take the quiz again to get your rank!`}
          </StyledText>
        </div>
      }
    </div>
  );
}

interface IWorkoutBreakdownSection {
  currentExcerciseTypeResults: ICurrentWorkout[];
  currentMuscleGroup: string;
}
const WorkoutBreakdownSection = ({currentExcerciseTypeResults, currentMuscleGroup}: IWorkoutBreakdownSection): JSX.Element => {
  const [showBenchmarks, setShowBenchmarks] = React.useState(Boolean(localStorage.getItem("showBenchamrks") === "true"));
  
  const topOfScrollRef = useRef<null | HTMLDivElement>(null);
  const currentExcerciseTypeAverage: number = Number(localStorage.getItem(currentMuscleGroup + "_average")) || 50;
  const bottomHalf: boolean = currentExcerciseTypeAverage > 50;
  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");

  const onChange = () => {
    localStorage.setItem("showBenchamrks", String(!showBenchmarks));
    setShowBenchmarks(!showBenchmarks);
  }

  return (
    <div className={`${STYLE_BASE}workoutBreakdowns`}>
      <div ref={topOfScrollRef}>
        <BenchmarkToggle onChange={onChange} showBenchmarks={showBenchmarks} />
        <WorkoutBreakdown
          bottomTextBold={bottomHalf ? `Bottom ${Math.abs(currentExcerciseTypeAverage-100)}% overall` : `Top ${currentExcerciseTypeAverage}% overall`}
          percentage={currentExcerciseTypeAverage}
          topText={`${snakeCaseToReadable(currentMuscleGroup)} Overall`}
        />
      </div>
      {
        currentExcerciseTypeResults.map((excercise: ICurrentWorkout) => {
          const workoutsDictionary: IWorkoutDictionaryValues = allWorkoutsDictionary[excercise.workoutName];
          const formUrl: string = workoutsDictionary.formUrl || '';
          const percentage: number = parseInt(excercise.percentage || "50", 10) || 50;
          const reps: string = excercise.reps && excercise.reps !== "1" ? `/ ${excercise.reps} reps` : '';
          const bottomHalf: boolean = percentage > 50;
          const bottomHalfText: string = `Bottom ${Math.abs(percentage-100)}%`;
          const topHalfText: string = `Top ${excercise.percentage}%`;
          const measurement: string = getMeasurementNameFromWorkoutName(excercise.workoutName, metric);

          const isTimeBasedMeasurement: boolean = measurement === "seconds" || measurement === "minutes";
          const multiplier: number = measurement === "seconds" ? 1 : 60;
          const smallerMeasurement: string = measurement === "seconds" ? "Secs" : "Mins";
          const largerMeasurement: string = measurement === "seconds" ? "Mins" : "Hours";
          const smallerValue: string = String((Number(excercise.workoutValue) % (60 * multiplier)) / multiplier);
          const largerValue: string = String(Math.floor(Number(excercise.workoutValue) / (60 * multiplier)));
          const bottomText: string = isTimeBasedMeasurement ? ` - ${largerValue} ${largerMeasurement}, ${smallerValue} ${smallerMeasurement}`
           : ` - ${excercise.workoutValue} ${measurement} ${reps}`;


          let benchmarks: IBenchmark[] = [];
          if (showBenchmarks) {
            const allBenchmarks: IBenchmark[] = JSON.parse(localStorage.getItem('benchmarks') || '[]') || [];
            benchmarks = allBenchmarks.filter((benchmark) => {
              if (benchmark.workoutName === excercise.workoutName) {
                const benchmarkValue: string = '' + benchmark.value || '';
                const benchmarkSmallerValue: string = String((Number(benchmarkValue) % (60 * multiplier)) / multiplier);
                const benchmarkLargerValue: string = String(Math.floor(Number(benchmarkValue) / (60 * multiplier)));
                const benchmarkTitle: string = `${benchmark.name}` + (isTimeBasedMeasurement ? 
                ` - ${benchmarkLargerValue} ${largerMeasurement}, ${benchmarkSmallerValue} ${smallerMeasurement}` :
                `- ${benchmark.value} ${measurement}${benchmark.reps !== 1 ? ' / ' + benchmark.reps + " reps" : ''}`);
                benchmark.title = benchmarkTitle;

                return true;
              }
              return false;
            });
          }

          const fiftyPercentageValue: string = excercise.fiftyPercentageValue || '';
          const fiftyPercentageSmallerValue: string = String((Number(fiftyPercentageValue) % (60 * multiplier)) / multiplier);
          const fiftyPercentageLargerValue: string = String(Math.floor(Number(fiftyPercentageValue) / (60 * multiplier)));
          const fiftyPercentageTitle: string = !fiftyPercentageValue ? "" :
          isTimeBasedMeasurement ? `${fiftyPercentageLargerValue} ${largerMeasurement}, ${fiftyPercentageSmallerValue} ${smallerMeasurement}`:
          `${fiftyPercentageValue} ${measurement} ${reps}`;

          return (
            <WorkoutBreakdown
              benchmarks={benchmarks}
              bottomText={bottomText}
              bottomTextBold={`${bottomHalf ? bottomHalfText : topHalfText}`}
              fiftyPercentageTitle={fiftyPercentageTitle}
              formUrl={formUrl}
              percentage={percentage}
              topText={snakeCaseToReadable(excercise.workoutName)}
              key={excercise.workoutName}
            />
          );
        })
      }
    </div>
  );
}

interface IBenchmarkToggle {
  onChange: () => void;
  showBenchmarks: boolean;
}
export const BenchmarkToggle = ({onChange, showBenchmarks}: IBenchmarkToggle): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}toggleBenchmarks`}>
      <Checkbox
        animation="smooth"
        // bigger
        checked={showBenchmarks}
        color="primary"
        icon={<i className="mdi mdi-check" />}
        onChange={onChange}
      />
      <StyledText
        type="text"
      >
        {SHOW_BENCHMARK_TEXT}
      </StyledText>
      <QuestionMarkHover 
        className={`${STYLE_BASE}showBenchmarksQuestionMarkHover`} 
        text={SHOW_BENCHMARK_TITLE} 
        textTwo={SHOW_BENCHMARK_TITLE_TWO}
      />
      <ReactTooltip
        className={"STYLED_TEXT-text"}
        place="top"
        multiline={true} 
      />
    </div>
  );
}

export const getCurrentMuscleGroupImgSrc: (muscleGroup: string) => string = (muscleGroup) => {
  switch(muscleGroup) {
    case "Upper_Body":
      return "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/muscleGroups/model-upperBody.png";
    case "Lower_Body":
      return "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/muscleGroups/model-lowerBody.png";
    case "Cardio":
      return "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/muscleGroups/model-cardio.png";
    case "Core":
      return "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/muscleGroups/model-core.png";
    default:
      return "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/muscleGroups/model-upperBody.png";
  }
}

export interface IMuscleGroupButton {
  name: string;
}
export const muscleGroupButtons: IMuscleGroupButton[] = [
  {
    name: "Upper_Body"
  },
  {
    name: "Lower_Body"
  },
  {
    name: "Core"
  },
  {
    name: "Cardio"
  },
];

export default ImproveSummary;