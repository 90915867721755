import React from 'react';
import WorkoutQuestionPage from '../../workout-question-page';
import { createWorkoutObject, ICurrentWorkout, ISetCurrentWorkouts, IWorkoutDictionaryValues, setCurrentWorkouts, updateWorkouts } from '../../../../constants';
import { MobileWorkoutQuestionPage } from 'containers/question-page/workout-question-page/workout-question-page';

const UpperBodyQuestions = (isMobile: boolean): JSX.Element => {
  const [pushUps, setPushUps] = React.useState("");
  const [pullUps, setPullUps] = React.useState("");
  const [benchPress, setBenchPress] = React.useState("");
  const [benchPressReps, setBenchPressReps] = React.useState("1");
  const [dumbbellCurl, setDumbbellCurl] = React.useState("");
  const [dumbbellCurlReps, setDumbbellCurlReps] = React.useState("1");
  const [powerClean, setPowerClean] = React.useState("");
  const [powerCleanReps, setPowerCleanReps] = React.useState("1");
  const [shoulderPress, setShoulderPress] = React.useState("");
  const [shoulderPressReps, setShoulderPressReps] = React.useState("1");

  React.useEffect(() => {
    const workoutsToSet: ISetCurrentWorkouts[] = [
      {setFunction: setPushUps, workoutName: "Push_Ups"},
      {setFunction: setPullUps, workoutName: "Pull_Ups"},
      {setFunction: setBenchPress, workoutName: "Bench_Press", setRepsFunction: setBenchPressReps},
      {setFunction: setDumbbellCurl, workoutName: "Dumbbell_Curl", setRepsFunction: setDumbbellCurlReps},
      {setFunction: setPowerClean, workoutName: "Power_Clean", setRepsFunction: setPowerCleanReps},
      {setFunction: setShoulderPress, workoutName: "Shoulder_Press", setRepsFunction: setShoulderPressReps},
    ];
    setCurrentWorkouts(workoutsToSet);
  }, []);

  React.useEffect(() => {
    const workoutsToUpdate: ICurrentWorkout[] = [
      {workoutName: "Push_Ups", workoutValue: pushUps},
      {workoutName: "Pull_Ups", workoutValue: pullUps},
      {workoutName: "Bench_Press", workoutValue: benchPress, reps: benchPressReps},
      {workoutName: "Dumbbell_Curl", workoutValue: dumbbellCurl, reps: dumbbellCurlReps},
      {workoutName: "Power_Clean", workoutValue: powerClean, reps: powerCleanReps},
      {workoutName: "Shoulder_Press", workoutValue: shoulderPress, reps: shoulderPressReps},
    ];
    updateWorkouts(workoutsToUpdate);
  }, [pushUps, pullUps, benchPress, benchPressReps, dumbbellCurl, dumbbellCurlReps, powerClean, powerCleanReps, shoulderPress, shoulderPressReps]);

  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");

  const upperBodyQuestionsArray: IWorkoutDictionaryValues[] = [
    createWorkoutObject("Push_Ups", pushUps, setPushUps, "1", null, metric),
    createWorkoutObject("Pull_Ups", pullUps, setPullUps, "1", null, metric),
    createWorkoutObject("Bench_Press", benchPress, setBenchPress, benchPressReps, setBenchPressReps, metric),
    createWorkoutObject("Dumbbell_Curl", dumbbellCurl, setDumbbellCurl, dumbbellCurlReps, setDumbbellCurlReps, metric),
    createWorkoutObject("Power_Clean", powerClean, setPowerClean, powerCleanReps, setPowerCleanReps, metric),
    createWorkoutObject("Shoulder_Press", shoulderPress, setShoulderPress, shoulderPressReps, setShoulderPressReps, metric),
  ];

  return isMobile ? 
    <MobileWorkoutQuestionPage workoutQuestions={upperBodyQuestionsArray} /> 
    : <WorkoutQuestionPage workoutQuestions={upperBodyQuestionsArray} />;
}

export default UpperBodyQuestions;
