import React from 'react';
import classNames from 'classnames';
import NumberInput from 'components/number-input';
import SliderBar from 'components/slider-bar';
import StyledButton from 'components/styled-button';
import StyledText from 'components/styled-text';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import { INPUT_WORKOUTS_NAME, INPUT_WORKOUT_QUESTION_HOVER, setAgeValue, TAKE_HOME_TEST_NAME, TAKE_HOME_TEST_QUESTION_HOVER } from '../intro-questions/intro-questions';
import './mobile-intro-questions.scss';

const STYLE_BASE = "MOBILE_INTRO_QUESTIONS-";

const MobileIntroQuestions = (): JSX.Element => {
  const [metric, setMetric] = React.useState(false);
  const [weight, setWeight] = React.useState(localStorage.getItem("weight") || "125");
  const [age, setAge] = React.useState("25");
  const [gender, setGender] = React.useState("Female");
  const [trialType, setTrialType] = React.useState(INPUT_WORKOUTS_NAME);

  React.useEffect(() => {
    setWeight(localStorage.getItem("weight") || "125");
    setAge(localStorage.getItem("age") || "25");
    setGender(localStorage.getItem("gender") || "Female");
    setMetric(Boolean(localStorage.getItem("metric") === "true"));
  }, []);

  React.useEffect(() => {
    localStorage.setItem("age", age);
  }, [age]);

  React.useEffect(() => {
    localStorage.setItem("gender", gender);
  }, [gender]);

  React.useEffect(() => {
    localStorage.setItem("weight", weight);
  }, [weight]);

  React.useEffect(() => {
    localStorage.setItem("metric", metric.toString());
    setWeight(localStorage.getItem("weight") || "125");
  }, [metric]);

  React.useEffect(() => {
    localStorage.setItem("trialType", trialType);
  }, [trialType]);

  const {
    convertMeasurementUserValues
  } = useQuizContext() as IQuizContextValues;

  return (
    <div className={`${STYLE_BASE}container`}>
      <div className={classNames(`${STYLE_BASE}questionArea`)}>
        <div className={classNames(`${STYLE_BASE}questionSection`, "smallMarginBottom")}>
          <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Age</StyledText>
        </div>
        <div className={`${STYLE_BASE}answerSection`}>
          <input
            className={classNames(`${STYLE_BASE}ageNumberInput`, "textSize")}
            onChange={(value) => setAgeValue(value, setAge)}
            type="number"
            value={age}
          />
          <SliderBar className={`${STYLE_BASE}sliderBar`} setValue={setAge} value={age} />
        </div>
      </div>

      <div className={classNames(`${STYLE_BASE}questionArea`)}>
        <div className={classNames(`${STYLE_BASE}questionSection`, "smallMarginBottom")}>
          <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Gender</StyledText>
        </div>
        <div className={`${STYLE_BASE}answerSection`}>
          <StyledButton
            className={classNames(`${STYLE_BASE}genderButton`, gender === "Female" ? `${STYLE_BASE}selectedButton` : '')}
            onClick={() => setGender("Female")}
            type="smallButton"
          >
            Female
          </StyledButton>
          <StyledButton
            className={classNames(`${STYLE_BASE}genderButton`, gender === "Male" ? `${STYLE_BASE}selectedButton` : '')}
            onClick={() => setGender("Male")}
            type="smallButton"
          >
            Male
          </StyledButton>
          <StyledButton
            className={classNames(`${STYLE_BASE}genderButton`, gender === "Non-binary" ? `${STYLE_BASE}selectedButton` : '')}
            onClick={() => setGender("Non-binary")}
            type="smallButton"
          >
            Non-binary
          </StyledButton>
        </div>
      </div>

      <div className={classNames(`${STYLE_BASE}questionArea`)}>
        <div className={classNames(`${STYLE_BASE}questionSection`, "smallMarginBottom")}>
          <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Weight</StyledText>
        </div>
        <div className={`${STYLE_BASE}answerSection`}>
          <div className={`${STYLE_BASE}numperInputAndMetricChoiceContainer`}>
            <NumberInput max="1400" measurement={metric ? 'kg' : 'lbs'} setValue={setWeight} value={weight} />
            <div className={`${STYLE_BASE}metricChoiceContainer`}>
              <StyledText 
                bold={!metric} 
                className={`${STYLE_BASE}us`}
                onClickFunction={() => {
                  if (metric === true) {
                    convertMeasurementUserValues(() => setMetric(false));
                  }
                }}
                third={metric}
                type="text"
              >
                US
              </StyledText>
              <StyledText 
                bold={metric} 
                className={`${STYLE_BASE}metric`}
                onClickFunction={() => {
                  if (metric === false) {
                    convertMeasurementUserValues(() => setMetric(true));
                  }
                }}
                third={!metric}
                type="text"
              >
                Metric
              </StyledText>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(`${STYLE_BASE}questionArea`)}>
        <div className={classNames(`${STYLE_BASE}questionSection`, "smallMarginBottom")}>
          <StyledText bold className={`${STYLE_BASE}questionName`} secondary type="bigText">Trial Type</StyledText>
        </div>
        <div className={`${STYLE_BASE}answerSection`}>
          <div className={`${STYLE_BASE}trialTypeContainer`}>
            <StyledButton
              className={classNames(trialType === INPUT_WORKOUTS_NAME ? `${STYLE_BASE}selectedButton` : '')}
              onClick={() => setTrialType(INPUT_WORKOUTS_NAME)}
              type="bigButton"
              QuestionMarkHoverInButton={INPUT_WORKOUT_QUESTION_HOVER}
            >
              {INPUT_WORKOUTS_NAME}
            </StyledButton>
          </div>
          <div className={`${STYLE_BASE}trialTypeContainer`}>
            <StyledButton
              className={classNames(trialType === TAKE_HOME_TEST_NAME ? `${STYLE_BASE}selectedButton` : '')}
              onClick={() => setTrialType(TAKE_HOME_TEST_NAME)}
              type="bigButton"
              QuestionMarkHoverInButton={TAKE_HOME_TEST_QUESTION_HOVER}
            >
              {TAKE_HOME_TEST_NAME}
            </StyledButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileIntroQuestions;
