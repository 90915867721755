import React, { useEffect } from 'react';
import classNames from 'classnames';
import Select, { SingleValue } from 'react-select';
import StyledText from 'components/styled-text';
import './dropdown-select.scss';
import { get } from 'lodash';

const STYLE_BASE = "DROPDOWN_SELECT-";

interface IDropdownSelect {
  className?: string;
  min?: number;
  max?: number;
  searchable?: boolean;
  setDropdownValue: React.Dispatch<React.SetStateAction<string>>;
  label?: string;
  value: string;
}

interface ICreateOptions {
  min: number;
  max: number;
}

const createOptions = ({min, max}: ICreateOptions): ({ value: string; label: string; })[] => {
  let optionsArray: ({ value: string; label: string; })[] = [];
  for(let i = min; i <= max; i++) {
    const stringVal: string = String(i);
    optionsArray.push({value: stringVal, label: stringVal});
  }

  return optionsArray;
}

const DropdownSelect = ({className, label="Reps", max=10, searchable=true, min=0, setDropdownValue, value}: IDropdownSelect): JSX.Element => {
  const [selectedOption, setSelectedOption] = React.useState(value);
  const selectedFormattedOption: SingleValue<{ value: string; label: string; }> = {value: value, label: value};

  useEffect(() => {
    setDropdownValue(selectedOption);
  }, [selectedOption, setDropdownValue]);

  return (
    <div className={classNames(`${STYLE_BASE}container`, {[`${STYLE_BASE}reps`]: label === "Reps"}, className)}>
      <Select
        className={`${STYLE_BASE}select`}
        isSearchable={searchable}
        options={createOptions({min, max})}
        onChange={
          (newValue: SingleValue<{ value: string; label: string; }>) => {
            const newVal: string = get(newValue, "value") || "1";
            setSelectedOption(newVal);
          }
        } 
        maxMenuHeight={200}
        menuPlacement="bottom"
        menuPosition="fixed"
        placeholder={selectedOption}
        value={selectedFormattedOption}
      />
      <StyledText className={`${STYLE_BASE}label`} type="smallTitle">{label}</StyledText>
    </div>
  );
}

export default DropdownSelect;
