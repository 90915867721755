import React from 'react';
import TakeHomeWorkoutQuestionPage from '../take-home-workout-question-page';
import { createWorkoutObject, ICurrentWorkout, ISetCurrentWorkouts, IWorkoutDictionaryValues, setCurrentWorkouts, updateWorkouts } from '../../../../../constants';

const TakeHomeCoreQuestions = (isMobile: boolean, nextQuestionFunction: () => void): JSX.Element => {
  const [sitUps, setSitUps] = React.useState("");

  React.useEffect(() => {
    const workoutsToSet: ISetCurrentWorkouts[] = [
      {setFunction: setSitUps, workoutName: "Sit_Ups"},
    ];
    setCurrentWorkouts(workoutsToSet);
  }, []);

  React.useEffect(() => {
    const workoutsToUpdate: ICurrentWorkout[] = [
      {workoutName: "Sit_Ups", workoutValue: sitUps},
    ];
    updateWorkouts(workoutsToUpdate);
  }, [sitUps]);

  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");

  const upperBodyQuestionsArray: IWorkoutDictionaryValues[] = [
    createWorkoutObject("Sit_Ups", sitUps, setSitUps, "1", null, metric),
  ];

  return (
    <TakeHomeWorkoutQuestionPage
      isMobile={isMobile}
      nextQuestionFunction={nextQuestionFunction}
      workoutQuestions={upperBodyQuestionsArray} 
    />
  );
}

export default TakeHomeCoreQuestions;
