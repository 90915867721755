import React from 'react';
import { useHistory } from 'react-router-dom';
import StyledButton from 'components/styled-button';
import StyledText from 'components/styled-text';
import './landing-section.scss';
import MobileLandingSection from '../landing-section-mobile';

const STYLE_BASE = "LANDING_SECTION-";
export const topIntroText: string = "Welcome to the most powerful fitness calculator in this realm and the next";
export const bottomIntroText: string = "Where will you be placed when you arrive at the gates of Swolehalla?";
const HOME_PAGE_IMAGE_URL: string = "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/home/FrontBanner_gold.png";

interface ILandingSection {
  isMobile: boolean;
}
const LandingSection = ({isMobile}: ILandingSection): JSX.Element => {

  const history = useHistory();

  if (isMobile) {
    return <MobileLandingSection />
  }

  return (
    <div className={`${STYLE_BASE}container`}>
      <div className={`${STYLE_BASE}titleTextAndButtonsContainer`}>
        <StyledText className="mediumMarginBottom" type="bigTitle">
          Swolehalla
        </StyledText>
        <StyledText className="smallMarginBottom" type="text">
          {topIntroText}
        </StyledText>
        <StyledText className="mediumMarginBottom" type="text">
          {bottomIntroText}
        </StyledText>
        <div className={`${STYLE_BASE}buttonsContainer`}>
          <StyledButton 
            className={`${STYLE_BASE}leftButton`} 
            onClick={()=> history.push('/quiz')}
          >
            Test my might
          </StyledButton>
          <StyledButton 
            onClick={() => history.push('/profile')}
          >
            My profile
          </StyledButton>
        </div>
      </div>
      <img
        alt="Path to Swolehalla"
        className={`${STYLE_BASE}homePageImage`} 
        src={HOME_PAGE_IMAGE_URL} 
        width="1920"
        height="1080"
      />
    </div>
  );
}

export default LandingSection;
