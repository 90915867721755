import React from 'react';
import StyledText from 'components/styled-text';
import './about.scss';
import SocialMenu from 'components/social-menu';
import StyledButton from 'components/styled-button';
import MobileAbout from '../mobile-about';

const STYLE_BASE = "ABOUT-";

export const topAboutText: string = "Swolehalla was made to be a fun and motivating website for fitness enthusiasts. The results are based off of various studies on the general population's fitness abilities. However, many of the values are assumptions and best guesses with the available data. Please have fun with the site, but don't take it too seriously it is not intended to be an exact science.";
export const bottomAboutText: string = "This is an ongoing project that will be improved over time. Including additional workouts, updated studies and new features. The fitness products listed in the profile page have affiliate links, giving us a percentage on sales. This hopefully will help us cover the server and labor costs for the website. Thanks for visiting Swolehalla!";
export const privacyPolicyText: string = "User data is only stored if user signs up for an account to maintain the information for the user. Data is never shared to a third party. User can request to have all their data deleted by emailing ascendingtoswolehalla@gmail.com. Swolehalla.com © 2002 - All rights reserved";
export const flatIconUrl: string = "https://www.flaticon.com/";
export const unSplashUrl: string = "https://unsplash.com/";
export const wtfDoTheyWantImageUrl: string = "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/about/wtfDoTheyWantImage.png";
export const wtfDoTheyWantUrl: string = "https://www.wtfdotheywant.com/";


export const creditsArray = [
  {
    imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/about/IanBarbour.jpg",
    name: "Ian Barbour",
    personalLink: 'https://www.linkedin.com/in/ianthebarbour',
    title: "Creator & Developer",
  },
  {
    imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/about/SuLiFixed.jpg",
    name: "Su Li",
    personalLink: 'https://www.lisuying.com/',
    title: "UI Designer",
  },
  {
    imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/about/AshleighDarbyFixed.jpg",
    name: "Ashleigh Darby",
    personalLink: "https://www.instagram.com/ashleighmichelleart/",
    title: "Illustrator",
  },
  {
    imageSrc: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/about/AnastaziaProvost.jpg",
    name: "Anastazia Provost",
    personalLink: "",
    title: "Graphic Artist",
  }
];

interface ICreditedPerson {
  imageSrc: string;
  name: string;
  personalLink: string;
  title: string;
}

const CreditedPerson = ({imageSrc, name, personalLink, title}: ICreditedPerson): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}personContainer`}>
      <a 
        className={`${STYLE_BASE}personLink`}
        href={personalLink} 
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <img
          alt={name}
          className={`${STYLE_BASE}personImage`} 
          src={imageSrc}
          width="600"
          height="300"
        />
      </a>
      <a 
        className={`${STYLE_BASE}personLink`}
        href={personalLink} 
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <StyledText bold className={`${STYLE_BASE}personName`} type="smallTitle">
          {name}
        </StyledText>
      </a>
      <a 
        className={`${STYLE_BASE}personLink`}
        href={personalLink} 
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <StyledText className={`${STYLE_BASE}personTitle`} secondary type="text">
          {title}
        </StyledText>
      </a>
    </div>
  );
}

interface IAbout {
  isMobile: boolean;
}
const About = ({isMobile}: IAbout): JSX.Element => {
  if (isMobile) {
    return <MobileAbout/>;
  }

  return (
    <div className={`${STYLE_BASE}container`}>
      <StyledText className="smallMarginBottom" type="title">
        About Swolehalla
      </StyledText>
      <div className={`${STYLE_BASE}textContainer`}>
        <StyledText className={`${STYLE_BASE}textSection`} type="text">
          {topAboutText}
        </StyledText>
        <StyledText className={`${STYLE_BASE}textSection`} type="text">
          {bottomAboutText}
        </StyledText>
      </div>
      <StyledText className="smallMarginBottom" type="smallTitle">
        {`Created By`}
      </StyledText>
      <div className={`${STYLE_BASE}creditsContainer`}>
        {creditsArray.map((person) => {
          return (
            <CreditedPerson 
              imageSrc={person.imageSrc}
              name={person.name}
              personalLink={person.personalLink}
              title={person.title}
              key={person.name}
            />
          );
        })}
      </div>
      <OtherSites />
      <div className={`${STYLE_BASE}bottomAboutContainer`}>
        <ExtraThanks/>
        <div className={`${STYLE_BASE}supportContainer`}>
          <a 
            href = "mailto:ascendingtoswolehalla@gmail.com"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <StyledButton className={`${STYLE_BASE}helpUsImproveButton`} onClick={() => null}>
              Help us improve
            </StyledButton>
          </a>
          <div className={`${STYLE_BASE}shareContainer`}>
            <SocialMenu preText="Share this website" />
          </div>
        </div>
      </div>
      <PrivacyPolicy />
    </div>
  );
}

export const OtherSites = (): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}otherSitesContainer`}>
      <StyledText className="smallMarginBottom" type="smallTitle">
        {`More From The Team`}
      </StyledText>
      <div className={`${STYLE_BASE}wtfDoTheyWantContainer`}>
        <a 
          className={`${STYLE_BASE}wtfTitleContainer`}
          href={wtfDoTheyWantUrl} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          title="Wtf Do They Want - The Ultimate Gift Finding Quiz"
        >
          <StyledText className={`${STYLE_BASE}wtfTitle`} type="smallTitle">
            {`WtfDoTheyWant.com`}
          </StyledText>
        </a>
        <div className={`${STYLE_BASE}wtfDoTheyWantDescriptionContainer`}>
          <StyledText type="text">
            The ultimate gift finding website. Find gift ideas by answering personality questions about your loved ones!
          </StyledText>
        </div>
        <a 
          className={`${STYLE_BASE}wtfImageContainer`}
          href={wtfDoTheyWantUrl} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
          title="Wtf Do They Want - The Ultimate Gift Finding Quiz"
        >
          <img
            alt={"Wtf Do They Want Website"}
            className={`${STYLE_BASE}wtfImage`} 
            src={wtfDoTheyWantImageUrl}
            width="1800"
            height="900"
          />
        </a>
      </div>
    </div>
  );
}

export const ExtraThanks = (): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}extraCreditsContainer`}>
      <StyledText className="smallMarginBottom" type="smallTitle">
        {`Extra Thanks`}
      </StyledText>
      <StyledText type="smallText">
        {`${flatIconUrl} - provided workout icons`}
      </StyledText>
      <StyledText type="smallText">
        {`${unSplashUrl} - provided open source images`}
      </StyledText>
    </div>
  );
}

export const PrivacyPolicy = (): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}privacyPolicyContainer`}>
      <StyledText className="smallMarginBottom" type="smallTitle">
        {`Privacy Policy`}
      </StyledText>
      <StyledText type="smallText">
        {`${privacyPolicyText}`}
      </StyledText>
    </div>
  );
}

export default About;
