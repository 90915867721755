import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import classNames from 'classnames';
import StyledButton from 'components/styled-button';
import StyledText from 'components/styled-text';
import { useHistory } from 'react-router-dom';
import { getPageDescriptions, IPageDescription, IQuizQuestionProps } from './constants';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import MobileQuestionPage from './mobile-question-page';
import './question-page.scss';

const STYLE_BASE = "QUESTION_PAGE-";

interface IQuestionPage {
  isMobile: boolean;
}
const QuestionPage = ({isMobile}: IQuestionPage): JSX.Element => {
  const {
    getQuizResults,
  } = useQuizContext() as IQuizContextValues;
  const [curPage, setCurPage] = useState(0);

  useEffect(() => {
    document.addEventListener("keydown", enterFunction, false);

    return () => {
      document.removeEventListener("keydown", enterFunction, false);
    };
    // eslint-disable-next-line
  }, [curPage]);

  const history = useHistory();

  const enterFunction = (event: any) => {
    if (event.keyCode === 13 && nextQuestionFunction) {
      nextQuestionFunction();
    }
  };

  const goToProfile: () => void = () => {
    history.push('/profile');
  };

  const finishQuizFunction: () => void = () => {
    getQuizResults();
    goToProfile();
  };
  const nextQuestionFunction: () => void = curPage === 4 ? () => finishQuizFunction() : () => setCurPage(curPage + 1);

  if (isMobile) {
    return <MobileQuestionPage curPage={curPage} setCurPage={setCurPage} />
  }

  return (
    <div className={`${STYLE_BASE}container`}>
      <RenderLeftPanel curPage={curPage} nextQuestionFunction={nextQuestionFunction} setCurPage={setCurPage} />
      <RenderRightPanel curPage={curPage} nextQuestionFunction={nextQuestionFunction} />
    </div>
  );
}

interface IRenderLeftPanel {
  curPage: number;
  nextQuestionFunction: () => void;
  setCurPage: Dispatch<SetStateAction<number>>;
}

const RenderLeftPanel = ({curPage, nextQuestionFunction, setCurPage}: IRenderLeftPanel): JSX.Element => {
  const history = useHistory();
  const curPageDescription: IPageDescription = getPageDescriptions(curPage, false);
  const title: string = curPageDescription.title;
  const descriptionOne: string = curPageDescription.descriptionOne;
  const descriptionTwo: string = curPageDescription.descriptionTwo;
  const descriptionThree: string | undefined = curPageDescription.descriptionThree;
  const progress: number = curPageDescription.progress;
  const imageUrl: string = curPageDescription.image;
  const nonComplete: number = 100 - progress;

  return (
    <div className={`${STYLE_BASE}leftPanel`} style={{backgroundImage: "url(" + imageUrl + ")"}}>
      <div className={`${STYLE_BASE}topBar`}>
        <StyledText 
          className={`${STYLE_BASE}title`}
          onClickFunction={() => history.push('/')} 
          type="bigTitle"
        >
          Swolehalla
        </StyledText>
      </div>
      <div className={`${STYLE_BASE}descriptionBar`}>
        <StyledText className={`${STYLE_BASE}descriptionTitle`} type="title">
          {title}
        </StyledText>
        <StyledText className={classNames(`${STYLE_BASE}description`, "smallMarginBottom")} type="text">
          {descriptionOne}
        </StyledText>
        <StyledText className={classNames(`${STYLE_BASE}description`, {"smallMarginBottom": !!descriptionThree})} type="text">
          {descriptionTwo}
        </StyledText>
        {descriptionThree &&
          <StyledText className={`${STYLE_BASE}description`} type="text">
            {descriptionThree}
          </StyledText>
        }
      </div>
      <div className={`${STYLE_BASE}progressContainer`}>
        <StyledText className={classNames(`${STYLE_BASE}progressText`, "largeMarginBottom")} type="smallText">
          {`Quiz progress: ${progress}%`}
        </StyledText>
        <div className={`${STYLE_BASE}progressBarContainer`}>
          <div className={`${STYLE_BASE}leftProgressBar`} style={{width:`${progress}%`}} />
          <div className={`${STYLE_BASE}rightProgressBar`} style={{width:`${nonComplete}%`}} />
        </div>
      </div>
      <div className={`${STYLE_BASE}navigationContainer`}>
        <div>
          {curPage !== 0 &&
            <StyledText
              onClickFunction={() => setCurPage(curPage - 1)}
              type="smallLink"
            >
              Previous question
            </StyledText>
          }
        </div>
        <StyledButton
          className={`${STYLE_BASE}nextButton`}
          onClick={nextQuestionFunction && (() => nextQuestionFunction())} 
          type="smallButton"
        >
          Next
        </StyledButton>
      </div>
    </div>
  );
}

interface IRenderRightPanel {
  curPage: number;
  nextQuestionFunction: () => void;
}

const RenderRightPanel = ({curPage, nextQuestionFunction}: IRenderRightPanel): JSX.Element => {
  const curPageDescription = getPageDescriptions(curPage, false);
  const CurQuestion: (nextQuestionFunction: IQuizQuestionProps) => JSX.Element = curPageDescription.quizQuestion;

  return (
    <div className={`${STYLE_BASE}rightPanel`}>
      <CurQuestion nextQuestionFunction={nextQuestionFunction} />
    </div>
  );
}

export default QuestionPage;
