import React from 'react';
import classNames from 'classnames';
import './auth-input.scss';
import { get } from 'lodash';

const STYLE_BASE = "AUTH_INPUT-";

interface IAuthInput {
  autoComplete?: string;
  className?: string;
  placeholder?: string;
  onBlur?: any
  onInputChange?: any;
  type?: string;
}

const AuthInput = ({autoComplete = "on", className, onBlur = () => null, onInputChange, placeholder, type}: IAuthInput): JSX.Element => {
  return (
    <input
      autoComplete={autoComplete}
      className={classNames(`${STYLE_BASE}authInput`, className, "textSize")}
      placeholder={placeholder}
      onBlur={(e) => onBlur(get(e, 'target.value') || '')}
      onChange={e => onInputChange(e.target.value)}
      type={type || "text"}
    />
  );
}

export default AuthInput;
