import React from 'react';
import WorkoutQuestionPage from '../../workout-question-page';
import { createWorkoutObject, ICurrentWorkout, ISetCurrentWorkouts, IWorkoutDictionaryValues, setCurrentWorkouts, updateWorkouts } from '../../../../constants';
import { MobileWorkoutQuestionPage } from 'containers/question-page/workout-question-page/workout-question-page';

const CoreQuestions = (isMobile: boolean): JSX.Element => {
  const [sitUps, setSitUps] = React.useState("");
  const [planks, setPlanks] = React.useState("");
  const [bicycles, setBicycles] = React.useState("");

  React.useEffect(() => {
    const workoutsToSet: ISetCurrentWorkouts[] = [
      {setFunction: setSitUps, workoutName: "Sit_Ups"},
      {setFunction: setPlanks, workoutName: "Planks"},
      {setFunction: setBicycles, workoutName: "Bicycles"},
    ];
    setCurrentWorkouts(workoutsToSet);
  }, []);

  React.useEffect(() => {
    const workoutsToUpdate: ICurrentWorkout[] = [
      {workoutName: "Sit_Ups", workoutValue: sitUps},
      {workoutName: "Planks", workoutValue: planks},
      {workoutName: "Bicycles", workoutValue: bicycles},
    ];
    updateWorkouts(workoutsToUpdate);
  }, [sitUps, planks, bicycles]);

  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");

  const coreQuestionsArray: IWorkoutDictionaryValues[] = [
    createWorkoutObject("Sit_Ups", sitUps, setSitUps, "1", null, metric),
    createWorkoutObject("Planks", planks, setPlanks, "1", null, metric),
    createWorkoutObject("Bicycles", bicycles, setBicycles, "1", null, metric),
  ];

  return isMobile ? 
    <MobileWorkoutQuestionPage workoutQuestions={coreQuestionsArray} /> 
    : <WorkoutQuestionPage workoutQuestions={coreQuestionsArray} />;
}

export default CoreQuestions;
