import React from 'react';
import StyledText from 'components/styled-text';
import NumberInput from 'components/number-input';
import { IWorkoutDictionaryValues } from '../../../constants';
import DropdownSelect from 'components/dropdown-select';
import './workout-question-page.scss';
import TimeInput from 'components/time-input';

const STYLE_BASE = "WORKOUT_QUESTION_PAGE-";

interface IWorkoutQuestionPage {
  workoutQuestions: Array<IWorkoutDictionaryValues>
}

const WorkoutQuestionPage = ({workoutQuestions}: IWorkoutQuestionPage): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}container`}>
      {
        workoutQuestions.map((workout: IWorkoutDictionaryValues) => {
          const isTimeInput: boolean = workout.measurement === "seconds" || workout.measurement === "minutes";
          const workoutValue: string = workout.value || '';

          return (
            <div className={`${STYLE_BASE}workoutSection`} key={`${workout.name}`}>
              <div className={`${STYLE_BASE}workoutNameAndIconContainer`}>
                <a 
                  className={`${STYLE_BASE}formLink`}
                  href={workout.formUrl} 
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  <StyledText bold className={`${STYLE_BASE}workoutName`} type="text">{workout.name}</StyledText>
                </a>
                <a 
                  className={`${STYLE_BASE}workoutIconContainer`} 
                  href={workout.formUrl} 
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  <img
                    alt={workout.name + " Icon"}
                    className={`${STYLE_BASE}workoutIcon`} 
                    src={workout.workoutIconUrl}
                    width="100"
                    height="100"
                  />
                </a>
              </div>
              {isTimeInput ?
                 <TimeInput 
                    max={Number(workout.max)} 
                    measurement={workout.measurement || ''} 
                    setValue={workout.setValue} 
                    value={workoutValue} 
                  />
              : 
                <div className={`${STYLE_BASE}inputsContainer`} >
                  <NumberInput 
                    max={workout.max} 
                    measurement={workout.measurement || ''} 
                    setValue={workout.setValue} 
                    value={workoutValue} 
                  />
                  {workout.supportsReps &&
                    <DropdownSelect
                      setDropdownValue={workout.setReps} 
                      value={workout.reps || "1"}
                    />
                  }
                </div>
              }
            </div>
          );
        })
      }
    </div>
  );
}

export const MobileWorkoutQuestionPage = ({workoutQuestions}: IWorkoutQuestionPage): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}container`}>
      {
        workoutQuestions.map((workout: IWorkoutDictionaryValues) => {
          const isTimeInput: boolean = workout.measurement === "seconds" || workout.measurement === "minutes";
          const workoutValue: string = workout.value || '';
            return (
              <div className={`${STYLE_BASE}workoutSection`} key={`${workout.name}`}>
                <div className={`${STYLE_BASE}workoutNameAndIconContainer`}>
                  <a 
                    className={`${STYLE_BASE}formLink`}
                    href={workout.formUrl} 
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    <StyledText bold className={`${STYLE_BASE}workoutName`} type="text">{workout.name}</StyledText>
                  </a>
                  <a 
                    className={`${STYLE_BASE}workoutIconContainer`} 
                    href={workout.formUrl} 
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                  >
                    <img
                      alt={workout.name + " Icon"}
                      className={`${STYLE_BASE}workoutIcon`} 
                      src={workout.workoutIconUrl}
                      width="75"
                      height="75"
                    />
                  </a>
                </div>
                {isTimeInput ?
                 <TimeInput 
                    max={Number(workout.max)} 
                    measurement={workout.measurement || ''}
                    searchable={false}
                    setValue={workout.setValue} 
                    value={workoutValue} 
                  />
              : 
                <div className={`${STYLE_BASE}inputsContainer`} >
                  <NumberInput 
                    max={workout.max} 
                    measurement={workout.measurement || ''} 
                    setValue={workout.setValue} 
                    value={workoutValue} 
                  />
                  {workout.supportsReps &&
                    <DropdownSelect
                      searchable={false}
                      setDropdownValue={workout.setReps} 
                      value={workout.reps || "1"}
                    />
                  }
                </div>
              }
              </div>
            );
        })
      }
    </div>
  );
}

export default WorkoutQuestionPage;
