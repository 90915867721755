// @ts-nocheck
import React, { ReactNode, useEffect, useState } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';
import QuestionPage from 'containers/question-page';
import HomePage from 'containers/home-page';
import ProfilePage from 'containers/profile-page';
import UnsubscribeLandingPage from 'containers/unsubscribe-landing-page';
import { IS_LOCAL } from '../../constants';

ReactGA.initialize('G-JXXS6T7V1W');

const history = createBrowserHistory();

if (!IS_LOCAL) {
  history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  });
}

const AppRouter = (): JSX.Element => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  let isMobile: boolean = (width <= 768);

  let CurrentHomePage: JSX.Element = <HomePage isMobile={isMobile}/>;
  let CurrentAboutPage: JSX.Element = <HomePage about isMobile={isMobile} />;
  let CurrentQuestionPage: ReactNode = <QuestionPage isMobile={isMobile} />;
  let CurrentProfilePage: ReactNode = <ProfilePage isMobile={isMobile} />;
  let CurrentUnsubscribeLandingPage: ReactNode = <UnsubscribeLandingPage isMobile={isMobile} />;

  return (
    <Router history={history}>
      <Route exact path ='/' render={() => CurrentHomePage} />
      <Route exact path ='/about' render={() => CurrentAboutPage} />
      <Route exact path ='/quiz' render={() => CurrentQuestionPage} />
      <Route exact path ='/profile' render={() => CurrentProfilePage} />
      <Route exact path ='/unsubscribe' render={() => CurrentUnsubscribeLandingPage}  />
    </Router>
  );
}

export default AppRouter;
