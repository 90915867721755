import React from 'react';
import get from 'lodash/get';
import ReCAPTCHA from "react-google-recaptcha";
import StyledText from 'components/styled-text';
import './signup-modal.scss';
import '@djthoms/pretty-checkbox';
import AuthInput from 'components/auth-input';
import {IAuthContextValues, useAuthContext} from 'contexts/authContext';
import StyledButton from 'components/styled-button';
import { Checkbox } from 'pretty-checkbox-react';
import { ICurrentWorkout, ISignupValues, IUserValues } from '../../../constants';

const STYLE_BASE = "SIGNUP_MODAL-";

const CAPTCHA_SITE_KEY: string = "6Leo1mIcAAAAANaYtsu7s2BEMRoCwLCzaQvqXe-9";

let captcha: any;

const handleResponse = (value: string | null, setCaptchaResponse: any) => {
  setCaptchaResponse(value);
}

interface ISignupModal {
  setIsSigningUp: any;
  setAccessToken: any;
}

const SignupModal = ({setAccessToken, setIsSigningUp}: ISignupModal): JSX.Element => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [wantsEmail, setWantsEmail] = React.useState(false);
  const [captchaResponse, setCaptchaResponse] = React.useState("");
  const [signupFailed, setSignupFailed] = React.useState("");

  const {
    signup
  } = useAuthContext() as IAuthContextValues;

  let signupValues: ISignupValues = {
    email: email,
    password: password,
    wantsEmail: wantsEmail,
    userValues: createUserValues(),
    captchaResponse: captchaResponse,
  };

  React.useEffect(() => {
    setSignupFailed("");
  }, [email, password]);

  React.useEffect(() => {
    // eslint-disable-next-line
    signupValues = {
      ...signupValues,
      email: email,
      password: password,
      wantsEmail: wantsEmail,
      captchaResponse: captchaResponse
    };
  }, [email, password, wantsEmail, captchaResponse]);

  return (
    <div className={`${STYLE_BASE}container`}>
      <StyledText
        className={`${STYLE_BASE}signUpText`}
        type="title"
      >
        Sign Up
      </StyledText>
      <StyledText
        className={`${STYLE_BASE}signUpExplanationText`}
        type="bigText"
      >
        Sign up to keep a record of your results and update your stats as you make gains
      </StyledText>
      {signupFailed &&
        <StyledText
          className={`${STYLE_BASE}signUpErrorText`}
          type="text"
        >
          {signupFailed}
        </StyledText>
      }
      <AuthInput 
        onInputChange={setEmail} 
        onBlur={() => setSignupFailed("")}
        placeholder="email" 
      />
      <AuthInput 
        autoComplete="new-password"
        onBlur={(password: string) => checkPassword(password, setSignupFailed)}
        onInputChange={setPassword} 
        placeholder="password" 
        type="password" 
      />
      <div className={`${STYLE_BASE}checkboxContainer`}>
        <Checkbox 
          animation="tada"
          bigger 
          color="success" 
          icon={<i className="mdi mdi-check"/>}
          onChange={() => setWantsEmail(!wantsEmail)}
        />
        <StyledText
          className={`${STYLE_BASE}emailCheckboxText`}
          onClickFunction={() => setWantsEmail(!wantsEmail)} 
          type="text"
        >
          Sign up for email updates and further analysis of your results
        </StyledText>
      </div>
      <ReCAPTCHA
        sitekey={CAPTCHA_SITE_KEY}
        onChange={(response) => handleResponse(response, setCaptchaResponse)}
        ref={el => { captcha = el; }}
      />
      <StyledButton 
        className={`${STYLE_BASE}signUpButton`}
        disabled={!captchaResponse || !!signupFailed}
        onClick={async () => {
          const response: string = await signup(signupValues, setAccessToken);
          if (response) {
            setSignupFailed(response);
            captcha.reset()
          } else {
            setIsSigningUp(false);
          }
        }}
      >
        Sign Up
      </StyledButton>
    </div>
  );
}

const createUserValues = (): IUserValues => {
  const overallAverage: string = localStorage.getItem('overallAverage') || "";
  const metric: boolean = Boolean(localStorage.getItem('metric')) || false;
  const gender: string = localStorage.getItem('gender') || "";
  const weight: string = localStorage.getItem('weight') || "";
  const age: string = localStorage.getItem('age') || "";
  const userWorkoutValuesObjects: ICurrentWorkout[] = JSON.parse(localStorage.getItem('workouts') || '{}') || {};
  const userWorkoutValues: ICurrentWorkout[] = Object.keys(userWorkoutValuesObjects).map(key => get(userWorkoutValuesObjects, key));

  return {
    overallAverage,
    metric,
    gender,
    weight,
    age,
    userWorkoutValues
  };
}

const checkPassword = (password: string, setSignupFailed: any) => {
  if (password.length < 7) {
    setSignupFailed("Password must be longer than 6 characters");
  } else {
    setSignupFailed("");
  }
}


export default SignupModal;
