import React from 'react';
import classNames from 'classnames';
import './question-mark-hover.scss';
import ReactTooltip from 'react-tooltip';
import StyledText from 'components/styled-text';

const STYLE_BASE = "QUESTION_MARK_HOVER-";

interface IQuestionMarkHover {
  className?: string;
  text: string;
  textTwo?: string;
}

const QuestionMarkHover = ({className, text, textTwo}: IQuestionMarkHover): JSX.Element => {
  const dataTip: string = textTwo ? text+"<br>"+textTwo : text;
  
  return (
    <div className={classNames(`${STYLE_BASE}container`, className)}>
      <StyledText
        className={classNames(`${STYLE_BASE}questionMark`, "smallTextSize")}
        dataTipText={dataTip}
      >
        ?
      </StyledText>
      <ReactTooltip
        className={`STYLED_TEXT-text`}
        place="top"
        multiline={true}
      />
    </div>
  );
}

export default QuestionMarkHover;
