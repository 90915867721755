import IntroQuestions from "./page-contents/intro-questions";
import MobileIntroQuestions from "./page-contents/mobile-intro-questions";
import UpperBodyQuestions from "./page-contents/upper-body-questions";
import LowerBodyQuestions from "./page-contents/lower-body-questions";
import CardioQuestions from "./page-contents/cardio-questions";
import CoreQuestions from "./page-contents/core-questions";
import { INPUT_WORKOUTS_NAME } from "./page-contents/intro-questions/intro-questions";
import TakeHomeUpperBodyQuestions from "./page-contents/take-home-pages/take-home-upper-body-questions";
import TakeHomeLowerBodyQuestions from "./page-contents/take-home-pages/take-home-lower-body-questions";
import TakeHomeCoreQuestions from "./page-contents/take-home-pages/take-home-core-questions";
import TakeHomeCardioQuestions from "./page-contents/take-home-pages/take-home-cardio-questions";

export interface IPageDescription {
    descriptionOne: string;
    descriptionTwo: string;
    descriptionThree?: string;
    image: string;
    progress: number;
    title: string;
    quizQuestion: (props: IQuizQuestionProps) => JSX.Element;
}

export interface IQuizQuestionProps {
    nextQuestionFunction: () => void;
}

export const getPageDescriptions = (page: number, isMobile: boolean) => {
    const isInputWorkoutsTrial: boolean = localStorage.getItem("trialType") === INPUT_WORKOUTS_NAME;

    const introQuestion: IPageDescription = {
        descriptionOne: "Judgement day has begun brothers and sisters of whey",
        descriptionTwo: "Begin by entering your basic information as to best rank you amongst your peers",
        descriptionThree: "Then decide on which trial you will take. Enter your known lifts or be guided through a take-home workout",
        image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/WorkoutImage.jpg",
        progress: 20,
        title: "Intro questions",
        quizQuestion: isMobile ? MobileIntroQuestions : IntroQuestions,
    };

    const pageDescriptions: IPageDescription[] = isInputWorkoutsTrial ? [
        introQuestion,
        {
            descriptionOne: "It begins. Provide the max abilities for all upperbody workouts in which you know your limits",
            descriptionTwo: "All workouts are skippable, it is not expected that you will be experienced in all forms",
            descriptionThree: "However, the more answers you provide, the more precise your ascension to Swolehalla will be forseen",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/UpperBodyImage.jpg",
            progress: 40,
            title: "Upper body",
            quizQuestion: () => UpperBodyQuestions(isMobile),
        },
        {
            descriptionOne: "Your true loyality to Brodin will be revealed here. Did thou skip the day of the leg?",
            descriptionTwo: "Provide the max abilities for all lowerbody workouts in which you know your limits",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/LowerBodyImage.jpg",
            progress: 60,
            title: "Lower body",
            quizQuestion: () => LowerBodyQuestions(isMobile),
        },
        {
            descriptionOne: "Has thy journey thus far led you down the iron washboard path?",
            descriptionTwo: "Provide the max abilities for all core workouts in which you know your limits",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/CoreImage.jpg",
            progress: 80,
            title: "Core",
            quizQuestion: () => CoreQuestions(isMobile),
        },
        {
            descriptionOne: "The end is nigh. Cardio does not always come naturally to those blessed by the way of the swole. But it must be considered all the same",
            descriptionTwo: "Provide the max abilities for all cardio workouts in which you know your limits",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/CardioImage.jpg",
            progress: 100,
            title: "Cardio",
            quizQuestion: () => CardioQuestions(isMobile),
        },
    ] 
    :
    [
        introQuestion,
        {
            descriptionOne: "This take-home test breaks the four major muscle groups into a four part challenge. One by one it will determine your final rank in Swolehalla",
            descriptionTwo: "It begins with the most classic fitness test, the two minute push-up. Click on the workout name for form details",
            descriptionThree: "Once you are prepared; hit the button, get into position, and when the countdown hits zero do as many push ups as you can within two minutes",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/UpperBodyImage.jpg",
            progress: 40,
            title: "Upper body",
            quizQuestion: ({nextQuestionFunction}: IQuizQuestionProps) => TakeHomeUpperBodyQuestions(isMobile, nextQuestionFunction),
        },
        {
            descriptionOne: "Your true loyality to Brodin will be revealed here. Did thou skip the day of the leg?",
            descriptionTwo: "Once you are prepared; hit the button, get into position, and when the countdown hits zero do as many bodyweight squats as you can within two minutes",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/LowerBodyImage.jpg",
            progress: 60,
            title: "Lower body",
            quizQuestion: ({nextQuestionFunction}: IQuizQuestionProps) => TakeHomeLowerBodyQuestions(isMobile, nextQuestionFunction),
        },
        {
            descriptionOne: "Has thy journey thus far led you down the iron washboard path?",
            descriptionTwo: "Once you are prepared; hit the button, get into position, and when the countdown hits zero do as many situps as you can within two minutes",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/CoreImage.jpg",
            progress: 80,
            title: "Core",
            quizQuestion: ({nextQuestionFunction}: IQuizQuestionProps) => TakeHomeCoreQuestions(isMobile, nextQuestionFunction),
        },
        {
            descriptionOne: "The end is nigh. Cardio does not always come naturally to those blessed by the way of the swole. But it must be considered all the same",
            descriptionTwo: "Once you are prepared; hit the button, get into position, and when the countdown hits zero do as many jumping jacks as you can within two minutes",
            image: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/quiz/CardioImage.jpg",
            progress: 100,
            title: "Cardio",
            quizQuestion: ({nextQuestionFunction}: IQuizQuestionProps) => TakeHomeCardioQuestions(isMobile, nextQuestionFunction),
        },
    ]
    ;
    return pageDescriptions[page];
};