import React from 'react';
import StyledText from 'components/styled-text';
import { ICurrentWorkout, getMeasurementNameFromWorkoutName, IBenchmark,} from '../../../../constants';
import { snakeCaseToReadable } from '../../../../util';
import { getMuscleGroup as getMuscleGroupInfo, IimproveMuscleGroup, IProducts } from '../constants';
import './mobile-improve-summary.scss';
import WorkoutBreakdown from '../workout-breakdown';
import classNames from 'classnames';
import { BenchmarkToggle, getMuscleGroupWorkouts } from '../improve-summary';
import { MONK_IMAGE_URL, SWOLE_MONK_EXPLANATION_ONE, SWOLE_MONK_EXPLANATION_TWO } from 'containers/profile-page/profile-page';

const STYLE_BASE = "MOBILE_IMPROVE_SUMMARY-";

interface IMobileImproveSummary {
  currentMuscleGroup: string;
}
const MobileImproveSummary = ({currentMuscleGroup}: IMobileImproveSummary): JSX.Element => {
  const muscleGroupInfo: IimproveMuscleGroup = getMuscleGroupInfo(currentMuscleGroup);
  const currentExcerciseTypeResults: ICurrentWorkout[] = getMuscleGroupWorkouts(currentMuscleGroup);
  const formattedCurrentMuscleGroup: string = currentMuscleGroup.toLowerCase().replace("_", " ");

  return (
    <div className={`${STYLE_BASE}improveSummary`}>
      {(!!currentExcerciseTypeResults.length) ? 
        <WorkoutBreakdownSection 
          currentExcerciseTypeResults={currentExcerciseTypeResults}
          currentMuscleGroup={currentMuscleGroup}
        /> :
        <div className={`${STYLE_BASE}noWorkoutsContainer`}>
          <StyledText
              className="largeMarginBottom"
              type="smallTitle"
            >
              {`No workouts entered for ${snakeCaseToReadable(currentMuscleGroup)}.`}
          </StyledText>
          <StyledText
              className="largeMarginBottom"
              type="smallTitle"
            >
              {`Please take the quiz again to get your rank!`}
          </StyledText>
        </div>
      }
      <StyledText
        className={classNames(`${STYLE_BASE}howToImprove`, "smallMarginBottom")}
        type="title"
      >
        How to Improve
      </StyledText>
      <div className={`${STYLE_BASE}resourceContainer`}>
        <StyledText
          type="text"
        >
          {`${muscleGroupInfo.description} your way to Swolehalla a mysteriously swole monk presents you their`}&nbsp;
        </StyledText>
        <a 
          href={muscleGroupInfo.workoutLink} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          <StyledText type="text">
            sacred texts
          </StyledText>
        </a>
        <StyledText type="text">
          &nbsp;
        </StyledText>
        <StyledText type="text">
        {`The engravings illuminate the path you must follow to fulfill your destiny.`}
        </StyledText>
        <br />
        <br />
        <StyledText type="text">
        {`${SWOLE_MONK_EXPLANATION_ONE}`}
        {`${SWOLE_MONK_EXPLANATION_TWO.replace("$s", formattedCurrentMuscleGroup)}`}
        </StyledText>
      </div>
      <div className={`${STYLE_BASE}productsAndMonkContainer`}>
        <img
          alt="Swole Sales Monk"
          className={`${STYLE_BASE}productMonkImage`}
          src={MONK_IMAGE_URL}
          height={430}
          width={300}
        />
        <div className={`${STYLE_BASE}productContainer`}>
          {
            muscleGroupInfo.products.map((product: IProducts) => {
              return <a 
                className={`${STYLE_BASE}product`}
                href={product.productUrl}
                key={product.title}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                <img
                  alt={product.title}
                  className={`${STYLE_BASE}productImage`} 
                  src={product.imageUrl} 
                  width="75"
                  height="75"
                />
              </a>
            })
          }
        </div>
      </div>
    </div>
  );
}

interface IWorkoutBreakdownSection {
  currentExcerciseTypeResults: ICurrentWorkout[];
  currentMuscleGroup: string;
}
const WorkoutBreakdownSection = ({currentExcerciseTypeResults, currentMuscleGroup}: IWorkoutBreakdownSection): JSX.Element => {
  const [showBenchmarks, setShowBenchmarks] = React.useState(Boolean(localStorage.getItem("showBenchamrks") === "true"));
  
  const currentExcerciseTypeAverage: number = Number(localStorage.getItem(currentMuscleGroup + "_average")) || 50;
  const bottomHalf: boolean = currentExcerciseTypeAverage > 50;
  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");
  
  const onChange = () => {
    localStorage.setItem("showBenchamrks", String(!showBenchmarks));
    setShowBenchmarks(!showBenchmarks);
  }

  return (
    <div className={`${STYLE_BASE}workoutBreakdowns`}>
      <BenchmarkToggle onChange={onChange} showBenchmarks={showBenchmarks} />
      <WorkoutBreakdown
        bottomTextBold={bottomHalf ? `Bottom ${Math.abs(currentExcerciseTypeAverage-100)}% overall` : `Top ${currentExcerciseTypeAverage}% overall`}
        percentage={currentExcerciseTypeAverage}
        topText={`${snakeCaseToReadable(currentMuscleGroup)} Overall`}
      />
      {
        currentExcerciseTypeResults.map((excercise: ICurrentWorkout) => {
          const percentage: number = parseInt(excercise.percentage || "50", 10) || 50;
          const reps: string = excercise.reps && excercise.reps !== "1" ? `/ ${excercise.reps} reps` : '';
          const bottomHalf: boolean = percentage > 50;
          const bottomHalfText: string = `Bottom ${Math.abs(percentage-100)}%`;
          const topHalfText: string = `Top ${excercise.percentage}%`;
          const measurement: string = getMeasurementNameFromWorkoutName(excercise.workoutName, metric);

          const isTimeBasedMeasurement: boolean = measurement === "seconds" || measurement === "minutes";
          const multiplier: number = measurement === "seconds" ? 1 : 60;
          const smallerMeasurement: string = measurement === "seconds" ? "Secs" : "Mins";
          const largerMeasurement: string = measurement === "seconds" ? "Mins" : "Hours";
          const smallerValue: string = String((Number(excercise.workoutValue) % (60 * multiplier)) / multiplier);
          const largerValue: string = String(Math.floor(Number(excercise.workoutValue) / (60 * multiplier)));
          const bottomText: string = isTimeBasedMeasurement ? ` - ${largerValue} ${largerMeasurement}, ${smallerValue} ${smallerMeasurement}`
           : ` - ${excercise.workoutValue} ${measurement} ${reps}`;

           let benchmarks: IBenchmark[] = [];
           if (showBenchmarks) {
            const allBenchmarks: IBenchmark[] = JSON.parse(localStorage.getItem('benchmarks') || '[]') || [];
            benchmarks = allBenchmarks.filter((benchmark) => {
              if (benchmark.workoutName === excercise.workoutName) {
                const benchmarkValue: string = '' + benchmark.value || '';
                const benchmarkSmallerValue: string = String((Number(benchmarkValue) % (60 * multiplier)) / multiplier);
                const benchmarkLargerValue: string = String(Math.floor(Number(benchmarkValue) / (60 * multiplier)));
                const benchmarkTitle: string = `${benchmark.name}` + (isTimeBasedMeasurement ? 
                ` - ${benchmarkLargerValue} ${largerMeasurement}, ${benchmarkSmallerValue} ${smallerMeasurement}` :
                `- ${benchmark.value} ${measurement}${benchmark.reps !== 1 ? ' / ' + benchmark.reps + " reps" : ''}`);
                benchmark.title = benchmarkTitle;
  
                return true;
              }
              return false;
            });
          }

           const fiftyPercentageValue: string = excercise.fiftyPercentageValue || '';
           const fiftyPercentageSmallerValue: string = String((Number(fiftyPercentageValue) % (60 * multiplier)) / multiplier);
           const fiftyPercentageLargerValue: string = String(Math.floor(Number(fiftyPercentageValue) / (60 * multiplier)));
           const fiftyPercentageTitle: string = !fiftyPercentageValue ? "" :
            isTimeBasedMeasurement ? `${fiftyPercentageLargerValue} ${largerMeasurement}, ${fiftyPercentageSmallerValue} ${smallerMeasurement}`
           : `${fiftyPercentageValue} ${measurement} ${reps}`;

          return (
            <WorkoutBreakdown
              benchmarks={benchmarks}
              bottomText={bottomText}
              bottomTextBold={`${bottomHalf ? bottomHalfText : topHalfText}`}
              fiftyPercentageTitle={fiftyPercentageTitle}
              percentage={percentage}
              topText={snakeCaseToReadable(excercise.workoutName)}
              key={excercise.workoutName}
            />
          );
        })
      }
    </div>
  );
}

export default MobileImproveSummary;