import React, { Fragment, useState } from 'react';
import './mobile-profile-page.scss';
import MobileProfilePageHeader from './mobile-profile-page-header';
import MobileProfilePageRanking from './mobile-profile-page-ranking';
import MobileImproveSummary from '../improve-summary/mobile-improve-summary';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import { NO_RESULTS_LOGGED_IN, NO_RESULTS_LOGGED_OUT } from '../profile-page';
import StyledText from 'components/styled-text';

const STYLE_BASE = "MOBILE_PROFILE_PAGE-";

interface IMobileProfilePage {
  
}
/* eslint-disable */
const MobileProfilePage = ({}: IMobileProfilePage): JSX.Element => {
  const [currentMuscleGroup, setCurrentMuscleGroup] = useState('Upper_Body');

  const {
    quizActionPending
  } = useQuizContext() as IQuizContextValues;

  const average: string = localStorage.getItem("overallAverage") || '';
  const accessToken: string = localStorage.getItem("accessToken") || '';
  const noResultsMessage: string = accessToken ? NO_RESULTS_LOGGED_IN : NO_RESULTS_LOGGED_OUT;

  return (
    <div className={`${STYLE_BASE}container`}>
      <MobileProfilePageHeader />
      {(!quizActionPending && !average) ?
          <StyledText
            className={`${STYLE_BASE}statsBreakdown`}
            type="bigText"
          >
            {noResultsMessage}
          </StyledText>
        :
        <Fragment>
          <MobileProfilePageRanking currentMuscleGroup={currentMuscleGroup} setCurrentMuscleGroup={setCurrentMuscleGroup} />
          <MobileImproveSummary currentMuscleGroup={currentMuscleGroup} />
        </Fragment>
      }

    </div>
  );
}

export default MobileProfilePage;