import React from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import './percentage-bar.scss';
import { IBenchmark } from '../../constants';

const STYLE_BASE = "PERCENTAGE_BAR";

interface IPercentageBar {
  benchmarks?: IBenchmark[];
  fiftyPercentageTitle?: string;
  percentage: number;
}

const PercentageBar = ({benchmarks, fiftyPercentageTitle, percentage}: IPercentageBar): JSX.Element => {
  const superiorGroupPercentage: number = Math.abs(percentage-100);
  const dataTip: string = !!fiftyPercentageTitle ? "50%<br>"+fiftyPercentageTitle.trim() : "50%";

  return (
    <div className={classNames(`${STYLE_BASE}-container`, "smallMarginBottom")}>
      <div 
        className={`${STYLE_BASE}-midPoint`}
        data-tip={dataTip}
      />
      {benchmarks &&
        benchmarks.map((benchmark: IBenchmark) => {
          const benchmarkTitles: string[] = benchmark.title?.split("-") || [];
          const benchmarkTitleTop: string = benchmarkTitles[0].trim();
          const benchmarkTitleBottom: string = `${benchmark.percentage}% - ` + benchmarkTitles[1].trim();
          
          return (
            <div 
              className={`${STYLE_BASE}-benchmarkContainer`}
              key={benchmark.name + benchmark.workoutName}
            >
              <div 
                className={`${STYLE_BASE}-benchmarkTooltipContainer`}
                data-tip={benchmarkTitleTop+"<br>"+benchmarkTitleBottom}
                style={
                  {
                    "--benchmark-color": benchmark.colorCode,
                    marginRight: (benchmark.percentage-1) + `%`,
                  } as React.CSSProperties
                }
              >
                <div 
                  className={`${STYLE_BASE}-benchmark`}
                  style={
                    {
                      "--benchmark-color": benchmark.colorCode,
                      top: ((benchmark.duplicatedValueCount * 15) - 20) + "px",
                    } as React.CSSProperties
                  }
                />
              </div>
              <ReactTooltip
                className={"STYLED_TEXT-text"}
                place="top"
                multiline={true}
              />
            </div>
          );
        })
      }
      <div className={classNames(`${STYLE_BASE}-barContainer`, "smallMarginBottom")}>
        <div className={`${STYLE_BASE}-empty`} style={{width: superiorGroupPercentage + `%`}} />
        <div className={`${STYLE_BASE}-filled`} style={{width: percentage + `%`}} />
      </div>
    </div>
  );
}

export default PercentageBar;
