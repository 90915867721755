import React, { Fragment } from 'react';
import classNames from 'classnames';
import './styled-text.scss';

const STYLE_BASE = "STYLED_TEXT";

interface IStyledText {
  bold?: boolean;
  children: {};
  className?: string;
  dataTipText?: string;
  onClickFunction?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  secondary?: boolean;
  third?: boolean;
  type?: string;
}

const StyledText = ({bold, children, className, dataTipText, onClickFunction, onMouseEnter, onMouseLeave, secondary, third, type="text"}: IStyledText): JSX.Element => {
  return (
    <Fragment>
      <div 
        className={classNames(
          `${STYLE_BASE}-container`, 
          `${STYLE_BASE}-${type}`,
          {[`${STYLE_BASE}-bold`]: bold}, 
          {[`${STYLE_BASE}-secondary`]: secondary},
          {[`${STYLE_BASE}-third`]: third}, 
          className,
          `${type}Size`
      )}
        data-tip={dataTipText}
        onClick={onClickFunction}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    </Fragment>
  );
}

export default StyledText;
