import React, {useEffect} from 'react';
import classNames from 'classnames';
import './countdown-timer.scss';
import StyledText from 'components/styled-text';
import { IWorkoutDictionaryValues } from '../../constants';
import NumberInput from 'components/number-input';
import StyledButton from 'components/styled-button';

const STYLE_BASE = "COUNTDOWN_TIMER-";

export const BEGIN: string = "BEGIN";
export const COUNTDOWN: string = "COUNTDOWN";
export const TIMER: string = "TIMER";
export const COMPLETED: string = "COMPLETED";

interface ICountdownTimer {
  beginText?: string;
  className?: string;
  nextQuestionFunction: () => void;
  timerStartCount: number;
  workoutQuestion: IWorkoutDictionaryValues;
}

interface IControlButtons {
  includeEnd?: boolean;
  includeNext?: boolean;
  nextQuestionFunction?: () => void;
}

const CountdownTimer = ({beginText = "Start", className, timerStartCount, nextQuestionFunction, workoutQuestion}: ICountdownTimer): JSX.Element => {
  const [curState, setCurState] = React.useState(BEGIN);

  const ControlButtons = ({includeEnd, includeNext, nextQuestionFunction}: IControlButtons): JSX.Element => {
    return (
      <div className={`${STYLE_BASE}controlButtonsContainer`}>
        <StyledButton
          className={`${STYLE_BASE}restartButton`}
          onClick={() => {
            setCurState(BEGIN);
          }}
        >
          Redo
        </StyledButton>
        {includeEnd &&
          <StyledButton
            className={`${STYLE_BASE}endButton`}
            onClick={() => {
              setCurState(COMPLETED);
            }}
          >
            End
          </StyledButton>
        }
        {includeNext &&
          <StyledButton
            className={`${STYLE_BASE}endButton`}
            onClick={() => {
              nextQuestionFunction && nextQuestionFunction();
            }}
          >
            Next
          </StyledButton>
        }
      </div>
    );
  }

  if (curState === BEGIN) {
    return (
      <div 
        className={classNames(`${STYLE_BASE}container`, className)} 
        onClick={() => setCurState(COUNTDOWN)}
      >
        <div className={`${STYLE_BASE}heightBlock`} />
        <div className={`${STYLE_BASE}circleContainer`}>
          <StyledText type='bigText' >
            {beginText}
          </StyledText>
        </div>
        <div className={`${STYLE_BASE}heightBlock`} />
      </div>
    );
  } else if (curState === COUNTDOWN) {
    return (
      <div className={classNames(`${STYLE_BASE}container`, className)} >
        <StyledText className={`${STYLE_BASE}heightBlock`} type='bigText'>
          Ready, Set...
        </StyledText>
        <Timer 
          formatted={false}
          goToNextState={() => setCurState(TIMER)}
          startTime={10}
        />
        <ControlButtons />
      </div>
    );
  }
  else if (curState === TIMER) {
    return (
      <div className={classNames(`${STYLE_BASE}container`, className)} >
        <StyledText className={`${STYLE_BASE}heightBlock`} type='bigText'>
          Go !
        </StyledText>
        <Timer 
          formatted
          goToNextState={() => setCurState(COMPLETED)}
          startTime={timerStartCount}
        />
        <ControlButtons includeEnd />
      </div>
    );
  }
  else {
    return (
      <div className={classNames(`${STYLE_BASE}container`, className)}>
        <StyledText className={`${STYLE_BASE}heightBlock`} type='bigText'>
          Enter Results
        </StyledText>
        <NumberInput
          className={`${STYLE_BASE}numberInput`}
          max={workoutQuestion.max} 
          measurement={workoutQuestion.measurement} 
          setValue={workoutQuestion.setValue} 
          value={workoutQuestion.value || ''} 
        />
        <ControlButtons includeNext nextQuestionFunction={nextQuestionFunction} />
      </div>
    );
  }
}

interface ITimer {
  formatted: boolean;
  goToNextState: () => void;
  startTime: number;
}
const Timer = ({formatted, goToNextState, startTime}: ITimer): JSX.Element => {
  const [countdownTime, setCountdownTime] = React.useState(startTime);

  useEffect(() => {
    setCountdownTime(startTime);
  }, [startTime]);

  useEffect(() => {
    if (countdownTime !== 0) {
      setTimeout(() => { 
        setCountdownTime(countdownTime - 1);
      }, 1000);
    }
    else if (countdownTime === 0) {
      goToNextState();
    }
    /* eslint-disable */
  }, [countdownTime]);

  if (formatted) {
    const minutes: number = Math.floor(countdownTime / 60);
    const seconds: number = countdownTime - (60 * minutes);
    const formattedSeconds: string = seconds > 9 ? seconds.toString() : '0' + seconds;

    return (
      <div className={`${STYLE_BASE}circleContainer`}>
        <StyledText type='bigText'>
          {`0${minutes}:${formattedSeconds}`}
        </StyledText>
      </div>
    );
  }

  return (
    <div className={`${STYLE_BASE}circleContainer`}>
      <StyledText type='bigTitle'>
        {countdownTime}
      </StyledText>
    </div>
  );
}

export default CountdownTimer;
