export interface IProducts {
    imageUrl: string;
    price: string;
    productUrl: string;
    types: string[];
    title: string;
}

export const products: IProducts[] = [
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/YogaMatFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B086P4NP6K/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B086P4NP6K&linkId=8c8a1cfa7d8ce988ca61d686bb14e99f",
        types: ["Core"],
        title: "Yoga Mat"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/WeightedVestFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B08SNXGZY2/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08SNXGZY2&linkId=8eede16c88ecfb8f7fab506fcbc53675",
        types: ["Cardio"],
        title: "Weighted Vest"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/FlatTreadmillFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B09GLK3PZH/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B09GLK3PZH&linkId=bdfd9d272306f69a7c7c1d8583d0ee01",
        types: ["Lower_Body", "Cardio"],
        title: "Folding Treadmill"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/WeightPulleyFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B08CKG217G/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08CKG217G&linkId=e7ba3fcbdb4bf1b5ec4fcc5d4382bede",
        types: ["Upper_Body"],
        title: "Weight Pulley"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/JumpRopeFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B01K8ZDYRM/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01K8ZDYRM&linkId=692759417047e579085902f3e716a596",
        types: ["Cardio"],
        title: "Weighted Jump Rope"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/PullUpBarFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B09959761L/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B09959761L&linkId=0c7eae2fe6c70b874a095fd41e2986a9",
        types: ["Upper_Body", "Core"],
        title: "Pull Up Bar"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/FitbitFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B09BXQ4HMB/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B09BXQ4HMB&linkId=d1927786a38dc875d6eb805039b03c67",
        types: ["Cardio"],
        title: "Fitbit"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/HydrationBackpackFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B00OXPC056/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00OXPC056&linkId=5ae0ba1f59bfe6cf29eb448ebe8cef00",
        types: ["Cardio"],
        title: "Hydration Backpack"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/AerobicStepFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B07TZSX3D3/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07TZSX3D3&linkId=5267ca8833e8b8cb09ed58548558c95a",
        types: ["Lower_Body"],
        title: "Aerobic Step"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/BenchPressFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B08DQPGSZ1/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08DQPGSZ1&linkId=9140748f01b7d3014541a658588df98d",
        types: ["Upper_Body"],
        title: "Adjustable Bench Press"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/YogaBallFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B07PF1PF3F/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07PF1PF3F&linkId=66a70d177027adfee10a1588213b331b",
        types: ["Core", "Cardio"],
        title: "Yoga Ball"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/DipStationFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B077Z8N3WW/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B077Z8N3WW&linkId=d15eb00c70c93f9df087867789b05173",
        types: ["Core", "Upper_Body"],
        title: "Dip Machine"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/AdjustableDumbbellsFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B001ARYU58/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B001ARYU58&linkId=10eaad18b6739acbe4a5457da4ffa487",
        types: ["Upper_Body", "Lower_Body", "Core"],
        title: "BowFlex Adjustable Dumbbells"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/BarWeightFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B07PT8TWRY/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07PT8TWRY&linkId=40ce159082fdc1d264ec09315f86a9ba",
        types: ["Upper_Body", "Lower_Body", "Core"],
        title: "Weighted Bars"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/WheyProteinFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B06XX78LKR/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B06XX78LKR&linkId=2ff89ddadb339ca8113ec53e74365116",
        types: ["Upper_Body", "Lower_Body", "Core"],
        title: "Whey Protein"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/PreWorkoutFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B00QYZ6MLG/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00QYZ6MLG&linkId=acc6841291abbe87f14ed1ff9abde77b",
        types: ["Upper_Body", "Lower_Body", "Core", "Cardio"],
        title: "Preworkout"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/BoxingBagSetFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B0923M75XV/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0923M75XV&linkId=4382b46c87bdc7031283098158937ec6",
        types: ["Upper_Body", "Lower_Body", "Core", "Cardio"],
        title: "Boxing Set"
    },
    {
        imageUrl: "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/products/RowingMachineFixed.jpg",
        price: "",
        productUrl: "https://www.amazon.com/gp/product/B075MB7DM8/ref=as_li_tl?ie=UTF8&tag=swolehalla-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B075MB7DM8&linkId=b4c28b12171586385ae6934847f2ca39",
        types: ["Upper_Body", "Lower_Body", "Core", "Cardio"],
        title: "Rowing Machine"
    },
];

const getProductList = (workoutGroup: string): IProducts[] => {
    let workoutGroupProducts: IProducts[] = [];
    products.forEach((product: IProducts) => {
        if (product.types.includes(workoutGroup)) {
            workoutGroupProducts.push(product);
        }
    });
    return workoutGroupProducts;
}

export interface IimproveMuscleGroup {
    description: string;
    products: IProducts[];
    workoutLink: string;
}

export const improveMuscleGroupArray: IimproveMuscleGroup[] = [
    {
        description: "As you pull",
        products: getProductList("Upper_Body"),
        workoutLink: "https://www.bodybuilding.com/content/build-that-freaky-upper-body-with-this-workout.html",
    },
    {
        description: "As you lunge",
        products: getProductList("Lower_Body"),
        workoutLink: "https://www.bodybuilding.com/content/emily-plajers-best-lower-body-workout.html",
    },
    {
        description: "As you crunch",
        products: getProductList("Core"),
        workoutLink: "https://www.bodybuilding.com/content/hard-core-training-3-muscle-building-core-workouts.html",
    },
    {
        description: "As you jog",
        products: getProductList("Cardio"),
        workoutLink: "https://www.bodybuilding.com/fun/beast52.htm",
    },
];

export const getMuscleGroup = (muscleGroup: string): IimproveMuscleGroup => {
    if (muscleGroup === "Upper_Body") {
        return improveMuscleGroupArray[0];
    } else if (muscleGroup === "Lower_Body") {
        return improveMuscleGroupArray[1];
    } else if (muscleGroup === "Core") {
        return improveMuscleGroupArray[2];
    } else {
        return improveMuscleGroupArray[3];
    }
}