import React, { useState, Dispatch, SetStateAction, useEffect, useRef, MutableRefObject } from 'react';
import classNames from 'classnames';
import StyledButton from 'components/styled-button';
import StyledText from 'components/styled-text';
import { useHistory } from 'react-router-dom';
import { getPageDescriptions, IQuizQuestionProps } from '../constants';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import './mobile-question-page.scss';

const STYLE_BASE = "MOBILE_QUESTION_PAGE-";

interface IMobileQuestionPage {
  curPage: number;
  setCurPage: Dispatch<SetStateAction<number>>;
}
const MobileQuestionPage = ({curPage, setCurPage}: IMobileQuestionPage): JSX.Element => {
  const {
    getQuizResults,
  } = useQuizContext() as IQuizContextValues;

  const history = useHistory();
  const topOfScrollRef = useRef<null | HTMLDivElement>(null);

  const goToProfile: () => void = () => {
    history.push('/profile');
  };

  return (
    <div className={`${STYLE_BASE}container`} ref={topOfScrollRef}>
      <RenderTopPanel curPage={curPage} setCurPage={setCurPage} />
      <RenderBottomPanel 
        curPage={curPage} 
        getQuizResults={getQuizResults} 
        goToProfile={goToProfile} 
        topOfScrollRef={topOfScrollRef}
        setCurPage={setCurPage} 
      />
    </div>
  );
}

interface IRenderTopPanel {
  curPage: number;
  setCurPage: Dispatch<SetStateAction<number>>;
}

const RenderTopPanel = ({curPage, setCurPage}: IRenderTopPanel): JSX.Element => {
  const history = useHistory();
  const curPageDescription = getPageDescriptions(curPage, true);
  const title: string = curPageDescription.title;
  const descriptionOne: string = curPageDescription.descriptionOne;
  const descriptionTwo: string = curPageDescription.descriptionTwo;
  const descriptionThree: string | undefined = curPageDescription.descriptionThree;
  const progress: number = curPageDescription.progress;
  const imageUrl: string = curPageDescription.image;
  const nonComplete: number = 100 - progress;


  return (
    <div 
      className={`${STYLE_BASE}topPanel`}
      style={{backgroundImage: "url(" + imageUrl + ")"}}
    >
      <div className={`${STYLE_BASE}topBar`}>
        <StyledText 
          className={`${STYLE_BASE}title`} 
          onClickFunction={() => history.push('/')} 
          type="smallTitle"
        >
          Swolehalla
        </StyledText>
      </div>
      <div className={`${STYLE_BASE}descriptionBar`}>
        <StyledText  className={classNames(`${STYLE_BASE}descriptionTitle`, "smallMarginBottom")} type="title">
          {title}
        </StyledText>
        <StyledText className={classNames(`${STYLE_BASE}description`, "smallMarginBottom")} type="text">
          {descriptionOne}
        </StyledText>
        <StyledText className={classNames(`${STYLE_BASE}description`, "largeMarginBottom")} type="text">
          {descriptionTwo}
        </StyledText>
        {descriptionThree &&
          <StyledText className={classNames(`${STYLE_BASE}description`, "largeMarginBottom")} type="text">
            {descriptionThree}
          </StyledText>
        }
      </div>
      <div className={`${STYLE_BASE}progressContainer`}>
        <StyledText className={classNames(`${STYLE_BASE}progressText`, "largeMarginBottom")} type="smallText">
          {`Quiz progress: ${progress}%`}
        </StyledText>
        <div className={`${STYLE_BASE}progressBarContainer`}>
          <div className={`${STYLE_BASE}leftProgressBar`} style={{width:`${progress}%`}} />
          <div className={`${STYLE_BASE}rightProgressBar`} style={{width:`${nonComplete}%`}} />
        </div>
      </div>
      {curPage !== 0 &&
        <StyledText
          className={`${STYLE_BASE}previousQuestionText`}
          onClickFunction={() => setCurPage(curPage - 1)}
          type="smallLink"
        >
          Previous question
        </StyledText>
      }
    </div>
  );
}

interface IRenderBottomPanel {
  curPage: number;
  getQuizResults: () => null;
  goToProfile: () => void;
  topOfScrollRef: MutableRefObject<HTMLDivElement | null>;
  setCurPage: Dispatch<SetStateAction<number>>;
}

const RenderBottomPanel = ({curPage, getQuizResults, goToProfile, topOfScrollRef, setCurPage}: IRenderBottomPanel): JSX.Element => {
  const enterFunction = (event: any) => {
    if (event.keyCode === 13 && nextQuestionFunction) {
      nextQuestionFunction();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", enterFunction, false);

    return () => {
      document.removeEventListener("keydown", enterFunction, false);
    };
    // eslint-disable-next-line
  }, [curPage]);

  const finishQuizFunction: () => void = () => {
    getQuizResults();
    goToProfile();
  };
  const nextQuestionFunction: () => void = curPage === 4 ? () => finishQuizFunction() : () => setCurPage(curPage + 1);
  const curPageDescription = getPageDescriptions(curPage, true);
  const CurQuestion: (nextQuestionFunction: IQuizQuestionProps) => JSX.Element = curPageDescription.quizQuestion;

  return (
    <div className={`${STYLE_BASE}bottomPanel`}>
      <CurQuestion nextQuestionFunction={nextQuestionFunction}/>
      <div className={`${STYLE_BASE}nextButtonContainer`}>
        <StyledButton
          className={`${STYLE_BASE}nextButton`}
          onClick={() => {
            if (null != topOfScrollRef && null != topOfScrollRef.current) {
              topOfScrollRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
              nextQuestionFunction();
            }
          }} 
          type="smallButton"
        >
          Next
        </StyledButton>
      </div>
    </div>
  );
}

export default MobileQuestionPage;
