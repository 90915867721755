import React, { FC } from 'react';
import classNames from 'classnames';
import './styled-button.scss';

const STYLE_BASE = "STYLED_BUTTON";

interface IStyledButton {
  children: {};
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  type?: string;
  QuestionMarkHoverInButton?: FC;
}

const StyledButton = ({children, className, disabled, onClick, type, QuestionMarkHoverInButton: QuestionMarkButton}: IStyledButton): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}-container`}>
      <button
        className={classNames(
          `${STYLE_BASE}-button`,
          `${STYLE_BASE}-${type}`,
          "textSize",
          className)}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
      {QuestionMarkButton && 
        <QuestionMarkButton />
      }
    </div>
  );
}

export default StyledButton;
