import React from 'react';
import classNames from 'classnames';
import './number-input.scss';
import StyledText from 'components/styled-text';

const STYLE_BASE = "NUMBER_INPUT";

interface INumberInput {
  className?: string;
  max?: string;
  measurement: string;
  min?: string;
  setValue: (value: string) => void;
  value: string;
}

const NumberInput = ({className, max="99999", measurement, min="0", setValue, value}: INumberInput): JSX.Element => {
  return (
    <div
      className={classNames(`${STYLE_BASE}-container`, className)}
    >
      <input 
        className={`${STYLE_BASE}-number`}
        inputMode="decimal"
        min={min}
        max={max}
        onChange={((e: React.FormEvent<HTMLInputElement>) => setValue(chooseValue(parseInt(max, 10), parseInt(min, 10), parseInt(e.currentTarget.value, 10))))}
        type="number" 
        value={value}
      />
      <StyledText className={`${STYLE_BASE}-measurement`} type="smallTitle">{measurement}</StyledText>
    </div>
  );
}

const chooseValue = (max: number, min: number, value: number): string => {
  if(value > max) {
    return max.toString();
  } else if (value < min) {
    return min.toString();
  } else {
    return value.toString();
  }
}

export default NumberInput;
