import React from 'react';
import StyledText from 'components/styled-text';
import './login-modal.scss';
import AuthInput from 'components/auth-input';
import {IAuthContextValues, useAuthContext} from 'contexts/authContext';
import StyledButton from 'components/styled-button';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';

const STYLE_BASE = "LOGIN_MODAL-";

interface ILoginModal {
  setIsLoggingIn: any;
  setAccessToken: any;
  setAverage: any;
}

const LoginModal = ({setAccessToken, setIsLoggingIn, setAverage}: ILoginModal): JSX.Element => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [failedLogin, setFailedLogin] = React.useState(false);

  const {
    login,
  } = useAuthContext() as IAuthContextValues;
  const {
    getBenchmarkResults
  } = useQuizContext() as IQuizContextValues;

  return (
    <div className={`${STYLE_BASE}container`}>
      <StyledText
        className={`${STYLE_BASE}logInText`}
        type="title"
      >
        Log in
      </StyledText>
      {failedLogin &&
        <StyledText
          className={`${STYLE_BASE}logInErrorText`}
          type="text"
        >
          Failed to login. Please check your login credentials and try again
        </StyledText>
      }
      <AuthInput onInputChange={setEmail} placeholder="email" />
      <AuthInput onInputChange={setPassword} placeholder="password" type="password" />
      <StyledButton 
        className={`${STYLE_BASE}logInButton`} 
        onClick={async () => {
          setFailedLogin(false);
          const loginSuccess: boolean = await login(email, password,  
            (accessToken: string) => {
              setAccessToken(accessToken);
              getBenchmarkResults();
            }, setAverage);
          if (loginSuccess) {
            setIsLoggingIn(false);
          } else {
            setFailedLogin(true);
          }
        }}
      >
        Sign In
      </StyledButton>
    </div>
  );
}

export default LoginModal;