import React from 'react';
import classNames from 'classnames';
import './modal.scss';
import StyledText from 'components/styled-text';

const STYLE_BASE = "MODAL-";

interface IModal {
  children: any;
  className?: string;
  showModal: boolean;
  closeModal: () => void;
}

const Modal = ({children, className, closeModal, showModal}: IModal): JSX.Element => {
  if(!showModal) {
    return <div/>;
  }

  return (
    <div className={classNames(`${STYLE_BASE}container`, className)}>
      <div className={classNames(`${STYLE_BASE}closeButtonContainer`)}>
        <StyledText           
          className={`${STYLE_BASE}closeButton`}
          onClickFunction={() => closeModal()}
          type="title"
        >
          X
        </StyledText>
      </div>
      {children}
    </div>
  );
}

export default Modal;
