import React, { Component, createContext, useContext } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import { allWorkoutsDictionary, BACKEND_URL, IBenchmark, IConvertedUserWorkoutValuesDto, ICurrentWorkout, ICurrentWorkouts, IUserValues, IWorkoutResults } from '../constants';
import { TAKE_HOME_TEST_NAME } from 'containers/question-page/page-contents/intro-questions/intro-questions';

export interface IQuizContextValues {
  convertMeasurementUserValues: any,
  getBenchmarkResults: () => void,
  getQuizResults: () => null,
  unsubscribeFromEmailList: (id: string, email: string) => Promise<Boolean>,
  quizActionFailed: boolean,
  quizActionMessage: string,
  quizActionPending: boolean,
}

export const QuizContext = createContext({});
export const useQuizContext = () => useContext(QuizContext);
export const QuizConsumer = QuizContext.Consumer;

const defaultState = {
  quizActionFailed: false,
  quizActionMessage: '',
  quizActionPending: false,
};

interface IState {
  quizActionFailed: boolean,
  quizActionMessage: string,
  quizActionPending: boolean,
}

export default class QuizProvider extends Component<{}, IState> {
  state = defaultState;

  callFailed = (failureMessage: string) => {
    this.setState({
      quizActionFailed: true,
      quizActionMessage: failureMessage,
      quizActionPending: false,
    });
  };

  callStart = () => {
    this.setState({
      quizActionMessage: '',
      quizActionPending: true,
    });
  };

  callSuccess = () => {
    this.setState({
      quizActionFailed: false,
      quizActionPending: false,
    });
  };

  convertIWorkoutResultsArrayToICurrentWorkouts = (workoutResultsByTypeArray: IWorkoutResults[]): ICurrentWorkouts => {
    const currentWorkoutsReformated: ICurrentWorkouts = {};
    workoutResultsByTypeArray.forEach((workoutResults: IWorkoutResults) => {
      const workoutTypeAverage: number = workoutResults.average;
      if (workoutTypeAverage !== null) {
        localStorage.setItem(workoutResults.exerciseType + "_average", workoutTypeAverage.toString());

        const workoutExercisePercentages: ICurrentWorkout[] = workoutResults.exercisePercentages;
        workoutExercisePercentages.forEach((workout: ICurrentWorkout) => {
          currentWorkoutsReformated[workout.workoutName] = workout;
        });
      } 
    });
    return currentWorkoutsReformated;
  }

  convertICurrentWorkoutArrayToICurrentWorkouts = (workouts: ICurrentWorkout[]): ICurrentWorkouts => {
    const currentWorkoutsReformated: ICurrentWorkouts = {};
    workouts.forEach((workout: ICurrentWorkout) => {
      currentWorkoutsReformated[workout.workoutName] = workout;
    });
    return currentWorkoutsReformated;
  }


  convertMeasurementUserValues = async (onSuccess: () => null) => {
    const unconvertedWorkouts: ICurrentWorkout[] = this.createWorkoutInputObject();
    const unconvertedIsMetric: boolean = Boolean(localStorage.getItem("metric") === "true");
    const unconvertedWeight: string = localStorage.getItem("weight") || "125";

    const response = await axios.post(`${BACKEND_URL}workout/convertedUserWorkoutValues?weight=${unconvertedWeight}&isMetric=${unconvertedIsMetric}`, unconvertedWorkouts);
    const success: boolean = get(response, 'status') === 200;

    if (success) {
      const convertedUserWorkoutValues: IConvertedUserWorkoutValuesDto = get(response, 'data');
      localStorage.setItem("weight", get(convertedUserWorkoutValues, 'weight'));
      const workouts: ICurrentWorkout[] = get(convertedUserWorkoutValues, 'userWorkoutValuesDtos');
      const convertedWorkouts: ICurrentWorkouts = this.convertICurrentWorkoutArrayToICurrentWorkouts(workouts);
      localStorage.setItem("workouts", JSON.stringify(convertedWorkouts));
      onSuccess && onSuccess();
      return;
    } 
    throw Error("Failed to get convertMeasurementUserValues");
  }

  createWorkoutInputObject = (): ICurrentWorkout[] => {
    let workoutInputObject: ICurrentWorkout[] = [];

    Object.keys(allWorkoutsDictionary).forEach((workoutName: string) => {
      const workouts: ICurrentWorkouts = JSON.parse(localStorage.getItem('workouts') || '{}') || {};
      const currentWorkout: ICurrentWorkout = get(workouts, workoutName);
      let workoutCountString: string | null = isNaN(parseInt(get(currentWorkout, 'workoutValue'))) ? null : (get(currentWorkout, 'workoutValue')).toString();

      if (workoutCountString) {
        const workoutCount: number = Math.floor(parseInt(workoutCountString, 10));
        workoutCountString = workoutCount.toString();
        workoutInputObject.push(
          {
            workoutName,
            workoutValue: workoutCountString,
            reps: currentWorkout.reps || "1"
          }
        )
      }
    });

    return workoutInputObject;
  }

  getBenchmarkResults = async (): Promise<void> => {
    const isMetric: boolean = Boolean(localStorage.getItem("metric") === "true");
    const gender: string = localStorage.getItem("gender") || "Female";
    const age: string = localStorage.getItem("age") || "25";
    const weight: string = localStorage.getItem("weight") || "125";

    try {
      this.callStart();
      const response = await axios.get(
        `${BACKEND_URL}workout/getBenchmarkPercentages`,
        {params: {
          age,
          gender,
          weight,
          isMetric
        }}
      );
      const success: boolean = get(response, 'status') === 200;

      if (success) {
        const benchmarks: IBenchmark[] = get(response, 'data') || [];
        localStorage.setItem("benchmarks", JSON.stringify(benchmarks));
        this.callSuccess();
      } else {
        this.callFailed("Failed to get benchmark percentages");
      }
    } catch (e) {
      this.callFailed("Failed to get benchmark percentages");
    }
  }

  getQuizResults = async (): Promise<void> => {
    this.callStart();

    const takeHomePath: string = localStorage.getItem("trialType") === TAKE_HOME_TEST_NAME ? "/takeHome" : '';
    const isMetric: boolean = Boolean(localStorage.getItem("metric") === "true");
    const gender: string = localStorage.getItem("gender") || "Female";
    const age: string = localStorage.getItem("age") || "25";
    let weight: string = localStorage.getItem("weight") || "125";

    try {
      const response = await axios.post(
        `${BACKEND_URL}workout/getExercisePercentages${takeHomePath}`,
        this.createWorkoutInputObject(),
        {params: {
          age,
          gender,
          weight,
          isMetric
        }}
      );
      const success: boolean = get(response, 'status') === 200;

      if (success) {
        this.getBenchmarkResults();

        const workoutResults: IWorkoutResults[] = get(response, 'data');
        let averageSum: number = 0;
        let resultCount: number = 0;
        const workouts: ICurrentWorkouts = this.convertIWorkoutResultsArrayToICurrentWorkouts(workoutResults);
        const userWorkoutValues: ICurrentWorkout[] = Object.values(workouts);
        workoutResults.forEach((workoutResult : IWorkoutResults) => {
          if (workoutResult['average']) {
            averageSum += workoutResult['average'];
            resultCount += 1;
          }
        });

        const overallAverage: number = Math.floor(averageSum/resultCount);

        localStorage.setItem("workouts", JSON.stringify(workouts));
        localStorage.setItem("overallAverage", overallAverage.toString());

        const userValues: IUserValues = {
          userWorkoutValues,
          overallAverage: overallAverage.toString(),
          gender,
          weight,
          age,
          metric: isMetric
        };

        const accessToken: string = localStorage.getItem("accessToken") || '';

        if (accessToken) {
          this.putUserWorkoutValues(userValues, accessToken);
        }

        this.callSuccess();

      } else {
        this.callFailed('');
      }
    } catch (e) {
      this.callFailed('');
    }
  };

  putUserWorkoutValues = async (userValues: IUserValues, accessToken: string): Promise<void> => {
    const username: string = localStorage.getItem("username") || "";
    const config = {
        'headers': { 'Authorization': `Bearer ${accessToken}` }
    };

    const response = await axios.put(`${BACKEND_URL}workout/workoutValues?username=${username}`, userValues, config);
    const success: boolean = get(response, 'status') === 200;

    if (success) {
        return;
      } 
    throw Error("Failed to get userWorkoutValues for " + username);
  };

  unsubscribeFromEmailList = async (id: string, email: string): Promise<Boolean> => {
    console.log("id", id)
    console.log("email", email)
    const response = await axios.put(`${BACKEND_URL}workout/unsubscribe/${email}/${id}`);
    const success: boolean = get(response, 'status') === 200;

    if (success) {
        return true;
      } 
    return false;
  };

  clearQuizActionState = () => {
    this.setState({
      quizActionFailed: false,
      quizActionMessage: '',
      quizActionPending: false,
    });
  };

  resetAccountData = () => {
    this.setState({
      ...defaultState,
    });
  };

  render(): JSX.Element {
    const {
      quizActionFailed,
      quizActionMessage,
      quizActionPending,
    } = this.state;

    return (
      <QuizContext.Provider
        value={{
          clearQuizActionState: this.clearQuizActionState,
          convertMeasurementUserValues: this.convertMeasurementUserValues,
          getBenchmarkResults: this.getBenchmarkResults,
          getQuizResults: this.getQuizResults,
          unsubscribeFromEmailList: this.unsubscribeFromEmailList,
          quizActionFailed,
          quizActionMessage,
          quizActionPending,
        }}
      >
      {this.props.children}
      </QuizContext.Provider>
    );
  }
}