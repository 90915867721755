import React, { useEffect } from 'react';
import classNames from 'classnames';
import './time-input.scss';
import DropdownSelect from 'components/dropdown-select';

const STYLE_BASE = "TIME_INPUT";

interface ITimeInput {
  className?: string;
  max?: number;
  min?: number;
  measurement: string;
  searchable?: boolean;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}

const TimeInput = ({className, max=10, min=0, measurement, searchable=true, setValue, value}: ITimeInput): JSX.Element => {
  const [largerTimeValue, setLargerTimeValue] = React.useState("0");
  const [smallerTimeValue, setSmallerTimeValue] = React.useState("0");
  const multiplier: number = measurement === "seconds" ? 1 : 60;

  useEffect(() => {
    const initialLargerValue: string = String(Math.floor(Number(value) / (60 * multiplier)));
    const initialSmallerValue: string = String((Number(value) % (60 * multiplier)) / multiplier);
    setLargerTimeValue(initialLargerValue);
    setSmallerTimeValue(initialSmallerValue);
    /* eslint-disable */
  }, [value]);

  useEffect(() => {
    const smallerTimeValueInSeconds: number = Number(smallerTimeValue) * multiplier;
    const largerTimeValueInSeconds: number = Number(largerTimeValue) * 60 * multiplier;

    const newValueInSeconds: string = String(smallerTimeValueInSeconds + largerTimeValueInSeconds);
    if (newValueInSeconds !== '0') {
      setValue(newValueInSeconds);
    }
  }, [largerTimeValue, smallerTimeValue]);

  let largerTimeLabel: string;
  let smallerTimeLabel: string;

  if (measurement === "seconds") {
    smallerTimeLabel = "Secs";
    largerTimeLabel = "Mins";
  } else {
    smallerTimeLabel = "Mins";
    largerTimeLabel = "Hours";
  }

  return (
    <div
      className={classNames(`${STYLE_BASE}-container`, className)}
    >
      <DropdownSelect
        className={`${STYLE_BASE}-firstInput`}
        label={largerTimeLabel}
        max={max}
        min={min}
        searchable={searchable}
        setDropdownValue={setLargerTimeValue}
        value={largerTimeValue}
      />
      <DropdownSelect
        className={`${STYLE_BASE}-secondInput`}
        label={smallerTimeLabel}
        max={59}
        min={0}
        searchable={searchable}
        setDropdownValue={setSmallerTimeValue}
        value={smallerTimeValue}
      />
    </div>
  );
}

export default TimeInput;
