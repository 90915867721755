import React, { Dispatch, SetStateAction } from 'react';
import StyledText from 'components/styled-text';
import { calculateSwolehallaRanking, IResultsArray } from '../../../../constants';
import { getInspirationalQuote } from 'containers/profile-page/profile-page';
import StyledButton from 'components/styled-button';
import { snakeCaseToReadable } from '../../../../util';
import './mobile-profile-page-ranking.scss';
import { getCurrentMuscleGroupImgSrc, IMuscleGroupButton, muscleGroupButtons } from 'containers/profile-page/improve-summary/improve-summary';

const STYLE_BASE = "MOBILE_PROFILE_PAGE_RANKING-";

interface IMobileProfilePageRanking {
  currentMuscleGroup: string;
  setCurrentMuscleGroup: Dispatch<SetStateAction<string>>;
}
const MobileProfilePageRanking = ({currentMuscleGroup, setCurrentMuscleGroup}: IMobileProfilePageRanking): JSX.Element => {
  const average: number = Number(localStorage.getItem("overallAverage")) || 50;
  const gender: string = localStorage.getItem("gender") || "Female";
  const bottomHalf: boolean = average > 50;
  const swolehallaRanking: IResultsArray = calculateSwolehallaRanking(gender, average);

  return (
    <div className={`${STYLE_BASE}container`}>
      <StyledText
        className={`${STYLE_BASE}rankingTitle`}
        type="title"
      >
        Final Judgement
      </StyledText>
      <div className={`${STYLE_BASE}personalizedRankingContainer`}>
        <img
          alt={swolehallaRanking.name}
          className={`${STYLE_BASE}rankingImage`} 
          src={swolehallaRanking.imageSrc}
          width="150"
          height="232"
        />
        <div className={`${STYLE_BASE}writtenRankingContainer`}>
          <StyledText
            type="smallTitle"
          >
            {swolehallaRanking.name}
          </StyledText>
          <StyledText
            bold
            secondary
            type="text"
          >
            {bottomHalf ? `Bottom ${Math.abs(average-100)}% overall` : `Top ${average}% overall`}
          </StyledText>  
        </div>
      </div>
      <div className={`${STYLE_BASE}inspirationalQuoteContainer`}>
        <StyledText
          type="bigText"
        >
          {getInspirationalQuote(gender)}
        </StyledText>
        <StyledText
          type="bigText"
        >
          - Brocrates
        </StyledText> 
      </div>
      <StyledText
        className={`${STYLE_BASE}statsBreakdown`}
        type="title"
      >
        Stats Breakdown
      </StyledText>
      <img
        alt={currentMuscleGroup}
        className={`${STYLE_BASE}currentMuscleGroupImage`} 
        src={getCurrentMuscleGroupImgSrc(currentMuscleGroup)} 
        width="150"
        height="232"
      />
      <div className={`${STYLE_BASE}chooseMuscleGroupContainer`}>
        {muscleGroupButtons.map((muscleGroup: IMuscleGroupButton) => {
          return (
            <StyledButton
              className={`${STYLE_BASE}muscleGroupButton`}
              onClick={() => {setCurrentMuscleGroup(muscleGroup.name)}}
              type={currentMuscleGroup === muscleGroup.name ? "selected" : ""}
              key={muscleGroup.name}
            >
              {snakeCaseToReadable(muscleGroup.name)}
            </StyledButton>
          );
        })}
      </div>
    </div>
  );
}

export default MobileProfilePageRanking;