import React from 'react';
import { useHistory } from 'react-router-dom';
import StyledText from 'components/styled-text';
import Modal from 'components/modal';
import HamburgerMenu from '../../../../components/hamburger-menu';
import { IHamburgerMenuOption } from '../../../../components/hamburger-menu/hamburger-menu';
import LoginModal from 'containers/profile-page/login-modal';
import SignupModal from 'containers/profile-page/signup-modal';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import './mobile-profile-page-header.scss';

const STYLE_BASE = "MOBILE_PROFILE_PAGE_HEADER-";

interface IMobileProfilePageHeader {
}
/* eslint-disable */
const MobileProfilePageHeader = ({}: IMobileProfilePageHeader): JSX.Element => {
  const [average, setAverage] = React.useState(Number(localStorage.getItem("overallAverage")) || 0);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [isSigningUp, setIsSigningUp] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState(localStorage.getItem("accessToken") || '');

  const history = useHistory();

  const {
    quizActionPending
  } = useQuizContext() as IQuizContextValues;

  React.useEffect(() => {
    setAverage(Number(localStorage.getItem("overallAverage")) || average);
    setAccessToken(localStorage.getItem("accessToken") || '');
  }, [quizActionPending, accessToken, average]);

  const username: string = localStorage.getItem("username") || '';
  const showModal: boolean = isLoggingIn || isSigningUp;
  const closeModal = isLoggingIn ? () => setIsLoggingIn(false) : () => setIsSigningUp(false);
  const ChildModal = isLoggingIn ? 
    <LoginModal setAccessToken={setAccessToken} setIsLoggingIn={setIsLoggingIn} setAverage={setAverage} /> :
    <SignupModal setAccessToken={setAccessToken} setIsSigningUp={setIsSigningUp} />;

  const signInOrSignOutMenuOption: IHamburgerMenuOption[] = !accessToken ? 
  [{
    function: () => {
      setIsLoggingIn(true);
      setMenuIsOpen(false);
    },
    title: "Login"
  },
  {
    function: () => {
      setIsSigningUp(true);
      setMenuIsOpen(false);
    },
    title: "Signup"
  }] :
  [
    {
      className: `${STYLE_BASE}username`,
      function: () => {},
      title: username,
    },
    {
    function: () => {
      localStorage.clear();
      history.push('/');
    },
    title: "Log out"
  }];

  const hamburgerOptions: IHamburgerMenuOption[] = signInOrSignOutMenuOption.concat([
    {
      function: () => {
        if (!accessToken) {
          localStorage.clear();
        }
        history.push('/');
      },
      title: "Retake Quiz"
    },
    {
      function: () => {
        history.push('/about');
      },
      title: "About"
    },
  ]);

  return (
    <div className={`${STYLE_BASE}container`}>
      <Modal children={ChildModal} closeModal={closeModal} showModal={showModal} />
      <StyledText
        onClickFunction={() => {
          history.push('/');
        }}
        type="title"
      >
        Swolehalla
      </StyledText>
      <HamburgerMenu 
        menuIsOpen={menuIsOpen} 
        setMenuIsOpen={setMenuIsOpen} 
        hamburgerOptions={hamburgerOptions}
        onOpen={() => {
          setIsSigningUp(false);
          setIsLoggingIn(false);
        }}
      />
    </div>
  );
}

export default MobileProfilePageHeader;