import React from 'react';
import StyledText from 'components/styled-text';
import { IWorkoutDictionaryValues } from '../../../../constants';
import './take-home-workout-question-page.scss';
import CountdownTimer from 'components/countdown-timer';

const STYLE_BASE = "TAKE_HOME_WORKOUT_QUESTION_PAGE-";

export interface ITakeHomeWorkoutQuestionPage {
  isMobile: boolean;
  nextQuestionFunction: () => void;
  workoutQuestions: Array<IWorkoutDictionaryValues>;
}

const TakeHomeWorkoutQuestionPage = ({isMobile, nextQuestionFunction, workoutQuestions}: ITakeHomeWorkoutQuestionPage): JSX.Element => {
  const takeHomeUpperBodyQuestion: IWorkoutDictionaryValues = workoutQuestions[0];

  const isDesktop: boolean = window.innerWidth >= 1600;
  const workoutIconImageHeightAndWidth: string = isDesktop ? "300" : "150";

  return (
    <div className={`${STYLE_BASE}container`}>
      <div className={`${STYLE_BASE}workoutNameAndIconContainer`}>
        <a 
          className={`${STYLE_BASE}formLink`}
          href={takeHomeUpperBodyQuestion.formUrl} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          <StyledText bold className={`${STYLE_BASE}workoutName`} type="bigText">{takeHomeUpperBodyQuestion.name}</StyledText>
        </a>
        <a 
          className={`${STYLE_BASE}workoutIconContainer`} 
          href={takeHomeUpperBodyQuestion.formUrl} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          <img
            alt={takeHomeUpperBodyQuestion.name + " Icon"}
            className={`${STYLE_BASE}workoutIcon`} 
            src={takeHomeUpperBodyQuestion.workoutIconUrl}
            width={workoutIconImageHeightAndWidth}
            height={workoutIconImageHeightAndWidth}
          />
        </a>
      </div>
      <div className={`${STYLE_BASE}countdownContainer`}>
        <CountdownTimer timerStartCount={120} nextQuestionFunction={nextQuestionFunction} workoutQuestion={takeHomeUpperBodyQuestion} />
      </div>
    </div>
  );
}

export default TakeHomeWorkoutQuestionPage;
