import React, { Component, createContext, useContext } from 'react';
import axios from 'axios';
import get from 'lodash/get';
import { BACKEND_URL, calculateSwolehallaRanking, ICurrentWorkout, ICurrentWorkouts, ISignupValues } from '../constants';

export interface IAuthContextValues {
    login: (email: string, password: string, onLoginSuccess: any, onGetUserValuesSuccess: any) => boolean,
    signup: (signupValues: ISignupValues, onSuccess: (accessToken: string) => void) => string,
    authActionFailed: boolean,
    authActionMessage: string,
    authActionPending: boolean,
}

export const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;

const defaultState = {
    authActionFailed: false,
    authActionMessage: '',
    authActionPending: false,
};

interface IState {
    authActionFailed: boolean,
    authActionMessage: string,
    authActionPending: boolean,
}

export default class AuthProvider extends Component<{}, IState> {
  state = defaultState;

  callFailed = (failureMessage: string) => {
    this.setState({
      authActionFailed: true,
      authActionMessage: failureMessage,
      authActionPending: false,
    });
  };

  callStart = () => {
    this.setState({
        authActionMessage: '',
        authActionPending: true,
    });
  };

  callSuccess = () => {
    this.setState({
        authActionFailed: false,
        authActionPending: false,
    });
  };

  clearAuthActionState = () => {
    this.setState({
        authActionFailed: false,
        authActionMessage: '',
        authActionPending: false,
    });
  };

login = async (username: string, password: string, onLoginSuccess: any, onGetUserValuesSuccess: any): Promise<boolean> => {
    this.callStart();

    try {
      const response = await axios.post(
        `${BACKEND_URL}api/auth/signin`,
        {
            username,
            password
        });
        const success: boolean = get(response, 'status') === 200;

        if (success) {
            const accessToken: string = get(response, 'data.accessToken');
            onLoginSuccess(accessToken);

            this.getUserValues(username, accessToken, onGetUserValuesSuccess);
            this.getUserWorkoutValues(username, accessToken, onGetUserValuesSuccess);

            localStorage.setItem("username", username);
            localStorage.setItem("accessToken", accessToken);

            this.callSuccess();
        } else {
            this.callFailed('');
            return false;
        }
    } catch (e) {
        this.callFailed('');
        return false;
    }
    return true;
};

getUserWorkoutValues = async (username: string, accessToken: string, onSuccess: () => null): Promise<void> => {
    const config = {
        'headers': { 'Authorization': `Bearer ${accessToken}` }
    };
        const response = await axios.get(`${BACKEND_URL}workout/userWorkoutValues?username=${username}`, config);
        const success: boolean = get(response, 'status') === 200;

    if (success) {
        const workoutsArray: ICurrentWorkout[] = JSON.parse(JSON.stringify(get(response, 'data')));
        const workouts: ICurrentWorkouts = {};
        workoutsArray.forEach((workout: ICurrentWorkout) => {
            workouts[workout.workoutName] = workout;
        });
        localStorage.setItem("workouts", JSON.stringify(workouts));
        onSuccess && onSuccess();
        return;
    } 
    throw Error("Failed to get userWorkoutValues for " + username);
};

getUserValues = async (username: string, accessToken: string, onSuccess: any): Promise<void> => {
        const config = {
            'headers': { 'Authorization': `Bearer ${accessToken}` }
        };
        const response = await axios.get(`${BACKEND_URL}workout/userValues?username=${username}`, config);
        const success: boolean = get(response, 'status') === 200;

        if (success) {
            const metric: boolean = get(response, 'data.metric');
            const overallAverage: string = get(response, 'data.overallAverage');
            const gender: string = get(response, 'data.gender');
            const weight: string = get(response, 'data.weight');
            const age: string = get(response, 'data.age');

            localStorage.setItem("metric", metric.toString());
            localStorage.setItem("overallAverage", overallAverage);
            localStorage.setItem("gender", gender);
            localStorage.setItem("weight", weight);
            localStorage.setItem("age", age);
            onSuccess(overallAverage)

            return;
        }
        throw Error("Failed to get userValues for " + username);
};

signup = async (signupValues: ISignupValues, onSuccess: (accessToken: string) => void): Promise<string> => {
        this.callStart();
        const rankingName: string = calculateSwolehallaRanking(signupValues.userValues.gender, Number(signupValues.userValues.overallAverage)).name
            || "Swoledier";
    
        try {
          const response = await axios.post(
            `${BACKEND_URL}api/auth/signup?rankingName=${rankingName}`,
            {
                ...signupValues
            }
        );
          const success: boolean = get(response, 'status') === 200;
    
          if (success) {
            this.callSuccess();
    
            const accessToken: string = get(response, 'data.accessToken');
            const username: string = get(response, 'data.username');
    
            localStorage.setItem("username", username);
            localStorage.setItem("accessToken", accessToken);
            onSuccess(accessToken);
          } else {
            this.callFailed('');
            return "Failed to sign up. Please double check your request";
          }
        } catch (e) {
          const response = get(e, 'response');
          if (get(response, 'message')) {
            return get(response, 'message');
          } else {
              return "Failed to sign up. Please double check your request";
          }
        }
        return '';
      };

  render(): JSX.Element {
    const {
        authActionFailed,
        authActionMessage,
        authActionPending,
    } = this.state;

    return (
      <AuthContext.Provider
        value={{
            login: this.login,
            signup: this.signup,
            authActionFailed,
            authActionMessage,
            authActionPending,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
