
export function camelToSnakeCase(key: string) {
    var result = key.replace( /([A-Z])/g, " $1" );
    result = result.charAt(0).toUpperCase() + result.slice(1)
    return result.split(' ').join('_');
}

export const snakeCaseToReadable = (s: string) => {
    // eslint-disable-next-line
    return s.replace(/(\_\w)/g, function(m){return ' ' + m[1].toUpperCase();});
}