import React, { useEffect } from 'react';
import classNames from 'classnames';
import StyledText from 'components/styled-text';
import './hamburger-menu.scss';

const HAMBURGER_ICON_URL = "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/HamburgerMenu.png";
const X_ICON_URL = "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/icons/xMark.png";

const openedMenu: React.LegacyRef<HTMLDivElement> = React.createRef();
const STYLE_BASE: string = "HAMBURGER_MENU-";

export interface IHamburgerMenuOption {
  className?: string;
  function: () => any;
  title: string;
}

interface IHamburgerMenu {
  menuIsOpen: boolean;
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hamburgerOptions: IHamburgerMenuOption[];
  onClose?: () => any;
  onOpen?: () => any;
}
const HamburgerMenu = ({menuIsOpen, setMenuIsOpen, hamburgerOptions, onClose = () => null, onOpen = () => null}: IHamburgerMenu): JSX.Element => {
  useEffect(() => {
    const handleClick = (e: { target: any; }) => {
      const current: any = openedMenu.current;
      if (current) {
        if (current.contains(e.target)) {
          return;
        }
        onClose();
        setMenuIsOpen(false);
      }
    }

    document.addEventListener('click', handleClick, false);
  
    // returned function will be called on component unmount 
    return () => {
      document.removeEventListener('click', handleClick, false);
    }
  }, [onClose, setMenuIsOpen])

  let hamburgerMenuIconHeight = "20";
  let hamburgerMenuIconWidth = "24";

  if (!menuIsOpen) {
    return (
      <img
        alt="Open hamburger Menu icon"
        className={`${STYLE_BASE}openHamburgerMenuIcon`}
        src={HAMBURGER_ICON_URL}
        onClick={() => {
          setTimeout(() => {
            onOpen();
            setMenuIsOpen(true);
          }, 25);
        }}
        height={hamburgerMenuIconHeight}
        width={hamburgerMenuIconWidth}
      />
    );
  } else {
    return (
      <div 
        className={classNames(`${STYLE_BASE}openHamburgerMenuContainer`, {[`${STYLE_BASE}openHamburgerMenuContainerClosed`]: !menuIsOpen} )} 
        ref={openedMenu}
      >
        <img
          alt="Close out hamburger Menu X"
          className={classNames(`${STYLE_BASE}closeHamburgerMenuX`, {[`${STYLE_BASE}hideElement`]: !menuIsOpen} )}
          src={X_ICON_URL}
          onClick={() => {
            onClose();
            setMenuIsOpen(false)
          }}
          height={hamburgerMenuIconHeight}
          width={hamburgerMenuIconWidth}
        />
        <div className={classNames(`${STYLE_BASE}hamburgerTitlesContainer`, {[`${STYLE_BASE}hideElement`]: !menuIsOpen} )}>
          {hamburgerOptions.map((hamburgerOption: IHamburgerMenuOption, i) => {
            return (
              <StyledText
                className={classNames(`${STYLE_BASE}hamburgerTitleStyle`, `${hamburgerOption.className}`)}
                onClickFunction={hamburgerOption.function}
                type="title"
                key={i}
              >
                {hamburgerOption.title}
              </StyledText>
            );
          })}
        </div>
      </div>
    );
  }
}

export default HamburgerMenu;
