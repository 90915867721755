import React from 'react';
import WorkoutQuestionPage from '../../workout-question-page';
import { createWorkoutObject, ICurrentWorkout, IWorkoutDictionaryValues, setCurrentWorkouts, updateWorkouts, ISetCurrentWorkouts } from '../../../../constants';
import { MobileWorkoutQuestionPage } from 'containers/question-page/workout-question-page/workout-question-page';

const CardioQuestions = (isMobile: boolean): JSX.Element => {
  const [fiveK, setFiveK] = React.useState("");
  const [jumpingJacks, setJumpingJacks] = React.useState("");
  const [halfMarathon, setHalfMarathon] = React.useState("");
  const [oneMileRun, setOneMileRun] = React.useState("");

  React.useEffect(() => {
    const workoutsToSet: ISetCurrentWorkouts[] = [
      {setFunction: setJumpingJacks, workoutName: "Jumping_Jacks"},
      {setFunction: setOneMileRun, workoutName: "One_Mile_Run"},
      {setFunction: setFiveK, workoutName: "Five_K"},
      {setFunction: setHalfMarathon, workoutName: "Half_Marathon"},
    ];
    setCurrentWorkouts(workoutsToSet);
  }, []);

  React.useEffect(() => {
    const workoutsToUpdate: ICurrentWorkout[] = [
      {workoutName: "Five_K", workoutValue: fiveK},
      {workoutName: "Jumping_Jacks", workoutValue: jumpingJacks},
      {workoutName: "Half_Marathon", workoutValue: halfMarathon},
      {workoutName: "One_Mile_Run", workoutValue: oneMileRun},
    ];
    updateWorkouts(workoutsToUpdate);
  }, [fiveK, jumpingJacks, halfMarathon, oneMileRun]);

  const metric: boolean = Boolean(localStorage.getItem("metric") === "true");

  const cardioQuestionsArray: IWorkoutDictionaryValues[] = [
    createWorkoutObject("Jumping_Jacks", jumpingJacks, setJumpingJacks, "1", null, metric),
    createWorkoutObject("One_Mile_Run", oneMileRun, setOneMileRun, "1", null, metric),
    createWorkoutObject("Five_K", fiveK, setFiveK, "1", null, metric),
    createWorkoutObject("Half_Marathon", halfMarathon, setHalfMarathon, "1", null, metric),
  ];

  return isMobile ? 
    <MobileWorkoutQuestionPage workoutQuestions={cardioQuestionsArray} /> 
    : <WorkoutQuestionPage workoutQuestions={cardioQuestionsArray} />;
}

export default CardioQuestions;
