import React, { useEffect } from 'react';
import StyledText from 'components/styled-text';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import './unsubscribe-landing-page.scss';
import { useHistory, useLocation } from 'react-router';

const STYLE_BASE = "UNSUBSCRIBE_LANDING-";


const UnsubscribeLandingPage = (): JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const id = query.get("id") || '';
  const email = query.get("email") || '';

  const {
    unsubscribeFromEmailList,
  } = useQuizContext() as IQuizContextValues;

  useEffect(() => {
    unsubscribeFromEmailList(id, email);
  }, [id, email, unsubscribeFromEmailList]);

  return (
    <div className={`${STYLE_BASE}container`}>
      <StyledText
        className={`${STYLE_BASE}returnToSite`}
        onClickFunction={() => {
          history.push('/');
        }}
        type="bigTitle"
      >
        Swolehalla
      </StyledText>
      <StyledText type='text'>
        You have successfully unsubscribed from the Swolehalla mailing list
      </StyledText>
    </div>
  );
}

export default UnsubscribeLandingPage;