import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { bottomIntroText, topIntroText } from "../landing-section/landing-section";
import StyledButton from 'components/styled-button';
import StyledText from 'components/styled-text';
import './mobile-landing-section.scss';

const STYLE_BASE = "MOBILE_LANDING_SECTION-";
const HOME_PAGE_MOBILE_IMAGE_URL: string = "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/home/FrontBannerMobile_gold.png";

const MobileLandingSection = (): JSX.Element => {

  const history = useHistory();

  return (
    <div className={`${STYLE_BASE}container`}>
      <img
        alt="Path to Swolehalla"
        className={classNames(`${STYLE_BASE}homePageImage`, "smallMarginBottom")}
        src={HOME_PAGE_MOBILE_IMAGE_URL}
        width="400"
        height="400"
      />
      <StyledText className="smallMarginBottom" type="bigTitle">
        Swolehalla
      </StyledText>
      <StyledText className="smallMarginBottom" type="text">
        {topIntroText}
      </StyledText>
      <StyledText className="largeMarginBottom" type="text">
        {bottomIntroText}
      </StyledText>
      <div className={classNames(`${STYLE_BASE}buttonsContainer`, "largeMarginBottom")}>
        <StyledButton 
          className={`${STYLE_BASE}leftButton`} 
          onClick={()=> history.push('/quiz')}
        >
          Test my might
        </StyledButton>
        <StyledButton 
          onClick={() => history.push('/profile')}
        >
          My profile
        </StyledButton>
      </div>
    </div>
  );
}

export default MobileLandingSection;
