import React from 'react';
import StyledText from 'components/styled-text';
import './workout-breakdown.scss';
import PercentageBar from 'components/percentage-bar';
import { IBenchmark } from '../../../../constants';

const STYLE_BASE = "WORKOUT_BREAKDOWN-";

interface IWorkoutBreakdown {
  benchmarks?: IBenchmark[];
  bottomText?: string;
  bottomTextBold?: string;
  formUrl?: string;
  fiftyPercentageTitle?: string;
  percentage: number;
  topText?: string;
  topTextBold?: string;
}

const WorkoutBreakdown = ({benchmarks, bottomText, bottomTextBold, formUrl, fiftyPercentageTitle, percentage, topText, topTextBold}: IWorkoutBreakdown): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}container`}>
      <div className={`${STYLE_BASE}textContainer`}>
        {(topText && !formUrl) &&
          <StyledText
              type="smallTitle"
            >
            {`${topText}`}
          </StyledText>
        }
        {(topText && formUrl) &&
          <a 
            className={`${STYLE_BASE}formUrlLink`}
            href={formUrl} 
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <StyledText
                className="smallMarginBottom"
                type="smallTitle"
              >
              {`${topText}`}
            </StyledText>
          </a>
        }
        {topTextBold &&
          <StyledText
              bold
              className="smallMarginBottom"
              type="text"
            >
            &nbsp;{`${topTextBold}`}
          </StyledText>
        }
      </div>
      <PercentageBar benchmarks={benchmarks} fiftyPercentageTitle={fiftyPercentageTitle} percentage={percentage} />
      {(bottomText || bottomTextBold) &&
        <div className={`${STYLE_BASE}textContainer`}>
          {bottomTextBold &&
            <StyledText
                bold
                className="smallMarginBottom"
                type="text"
              >
              {`${bottomTextBold}`}
            </StyledText>
          }
          {bottomText &&
            <StyledText
                className="smallMarginBottom"
                type="text"
              >
              &nbsp;{`${bottomText}`}
            </StyledText>
          }
        </div>
      }
    </div>
  );
}

export default WorkoutBreakdown;