import React from 'react';
import classNames from 'classnames';
import StyledText from 'components/styled-text';
import { maleResultsArray, femaleResultsArray, IResultsArray } from '../../../constants';
import './fitness-types.scss';

const STYLE_BASE = "FITNESS_TYPES-";

interface IFitnessType {
  className?: string;
  isMobile: boolean;
  imageSrc: string;
  name: string;
  topPercentage: string;
}
const FitnessType = ({className = "", isMobile, imageSrc, name, topPercentage}: IFitnessType): JSX.Element => {

  if (isMobile) {
    return (
      <div className={classNames(`${STYLE_BASE}fitnessTypeContainer`, {[className]: !!className})}>
        <img
          alt={name}
          className={`${STYLE_BASE}fitnessTypeImageMobile`} 
          src={imageSrc}
          width="100"
          height="155"
        />
        <div className={`${STYLE_BASE}fitnessTypeTextContainer`}>
          <StyledText bold type="smallTitle">
            {name}
          </StyledText>
          <StyledText className="smallMarginBottom" secondary type="text">
            {topPercentage}
          </StyledText>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(`${STYLE_BASE}fitnessTypeContainer`, {[className]: !!className})}>
      <img
        alt={name}
        className={`${STYLE_BASE}fitnessTypeImage`} 
        src={imageSrc}
        width="140"
        height="220"
      />
      <div className={`${STYLE_BASE}fitnessTypeTextContainer`}>
        <StyledText bold className="smallMarginBottom" type="smallTitle">
          {name}
        </StyledText>
        <StyledText className="smallMarginBottom" secondary type="text">
          {topPercentage}
        </StyledText>
      </div>
    </div>
  );
}


interface IFitnessTypes {
  male: boolean;
  isMobile: boolean;
}
const FitnessTypes = ({male, isMobile}: IFitnessTypes): JSX.Element => {
  const currentResultsArray: IResultsArray[] = male ? maleResultsArray : femaleResultsArray;

  return (
    <div className={classNames(`${STYLE_BASE}container`, {[`${STYLE_BASE}maleBackground`]: male})}>
      <div className={`${STYLE_BASE}fitnessTypeTitleContainer`}>
        <StyledText className="mediumMarginBottom" type="title">
          {`${male ? "Male" : "Female"} Rankings`}
        </StyledText>
      </div>
      <div className={`${STYLE_BASE}fitnessTypesContainer`}>
        {currentResultsArray.map((fitnessType) => {
          const topRanking: boolean = fitnessType.topPercentage === "Top 1%";
          return (
            <FitnessType
              className={topRanking ? `${STYLE_BASE}topRanking`: ''}
              isMobile={isMobile}
              imageSrc={fitnessType.imageSrc} 
              name={fitnessType.name}
              topPercentage={fitnessType.topPercentage}
              key = {fitnessType.name}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FitnessTypes;
