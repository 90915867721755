import React from 'react';
import StyledText from 'components/styled-text';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,

  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
} from 'react-share';
import { WEBSITE_URL } from '../../constants';
import './styles.scss';

const STYLE_BASE = "SOCIAL_MENU-";
const SHARE_TEXT: string = "The most powerful fitness calculator in this realm and the next. Where will you be placed when you arrive at the gates of Swolehalla?";

interface ISocialMenu {
  customIconSize?: number;
  preText?: string;
  url?: string;
  shareQouteText?: string;
}
const SocialMenu = ({customIconSize = 40, preText, url = WEBSITE_URL, shareQouteText = SHARE_TEXT}: ISocialMenu): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}socialShareContainer`}>
      {preText && (
        <StyledText
          className={`${STYLE_BASE}pretext`}
          type="text"
        >
          {preText}
        </StyledText>
      )}
      <div className="socialIconsContainer">
        <FacebookShareButton
          url={url}
          quote={shareQouteText}
          className={`${STYLE_BASE}shareButton`}
        >
          <FacebookIcon
            size={customIconSize}
            round
          />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={shareQouteText}
          className={`${STYLE_BASE}shareButton`}
        >
          <TwitterIcon
            size={customIconSize}
            round
          />
        </TwitterShareButton>
        <PinterestShareButton
          url={url}
          description={shareQouteText}
          media="https://www.wtfdotheywant.com/metaimage.png"
          className={`${STYLE_BASE}shareButton`}
        >
          <PinterestIcon
            size={customIconSize}
            round
          />
        </PinterestShareButton>
      </div>
    </div>
  );
}

export default SocialMenu;
