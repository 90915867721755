import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import StyledText from 'components/styled-text';
import { calculateSwolehallaRanking, IResultsArray } from '../../constants';
import { IQuizContextValues, useQuizContext } from 'contexts/quizContext';
import ImproveSummary from './improve-summary';
import Modal from 'components/modal';
import LoginModal from './login-modal';
import SignupModal from './signup-modal';
import MobileProfilePage from './mobile-profile-page';
import './profile-page.scss';
import { IProducts, IimproveMuscleGroup, getMuscleGroup } from './improve-summary/constants';

const STYLE_BASE = "PROFILE_PAGE-";
export const MONK_IMAGE_URL: string = "https://elasticbeanstalk-us-west-2-760548605021.s3.us-west-2.amazonaws.com/resources/images/profile/monkSalesmenResized.png";
export const NO_RESULTS_LOGGED_IN: string = "No results found for this account please take the quiz to get your results";
export const NO_RESULTS_LOGGED_OUT: string = "No answers found please login or take the quiz to get your results";
export const SWOLE_MONK_EXPLANATION_ONE: string = `The yoked monk looks upon you with a knowing smile as he reveals his mighty wares fit for Brodin himself. `;
export const SWOLE_MONK_EXPLANATION_TWO: string = `You are on the righteous $s path, these iron instruments will assist you in reaching this holy summit.`;

export const getInspirationalQuote: (gender: string) => string = (gender) => {
  const personalGenderedString: string = gender === "Male" ? "man" :
  gender === "Female" ? "woman" : "person";
  const partnerGenderedString: string = gender === "Male" ? "women" :
  gender === "Female" ? "men" : "people";

  const quoteOptions: string[] = [
    `"It is a shame for a ${personalGenderedString} to grow old without seeing the beauty and strength of which their body is capable."`,
    `"When I went to school, they asked me what I wanted to be when I grew up. I wrote down ‘swole’. They told me I didn’t understand the assignment, I told them they didn’t understand life."`,
    `"I was told ${partnerGenderedString} don't like if you are too strong, so I put the iron down. and picked it back up again... repeatedly. I care naught what they think"`,
    `"The person I was dating told me that I work out too much, so I stopped going to the gym… Only once a day since I have all this free time now."`,
    `"Brothers and sisters, I fear I may be allergic to iron. For everytime I touch the stuff, I get more swollen."`,
    // `"In Brodin's eyes, all are equal. I will pray extra hard in holy sets of 5x5 tonight for all those who just started the path. May your whey goblet never be empty, and your reps be bountiful and heavy."`
  ];
  return quoteOptions[Math.floor(Math.random() * quoteOptions.length)];
}

export interface IMuscleGroupImage {
  src: string;
}

interface IProfileSummary {
  average: string;
  currentMuscleGroup: string;
  gender: string;
  swolehallaRanking: IResultsArray;
}
const ProfileSummary = ({average, currentMuscleGroup, gender, swolehallaRanking}: IProfileSummary): JSX.Element => {
  const muscleGroupInfo: IimproveMuscleGroup = getMuscleGroup(currentMuscleGroup);
  const averageNumber: number = Number(average);
  const bottomHalf: boolean = averageNumber > 50;
  const formattedCurrentMuscleGroup: string = currentMuscleGroup.toLowerCase().replace("_", " ");

  const isDesktop: boolean = window.innerWidth >= 1600;
  const swolehallaRankingImageHeight: string = isDesktop ? "345" : "230";
  const swolehallaRankingImageWidth: string = isDesktop ? "225" : "150";
  
  return (
    <div className={`${STYLE_BASE}profileSummary`}>
      <StyledText
        className="largeMarginBottom"
        type="title"
      >
        Final Judgement
      </StyledText>
      <div className={`${STYLE_BASE}profileContainer`}>
        <img
          alt={swolehallaRanking.name}
          className={`${STYLE_BASE}rankingImage`} 
          src={swolehallaRanking.imageSrc} 
          width={swolehallaRankingImageWidth}
          height={swolehallaRankingImageHeight}
        />
        <div className={`${STYLE_BASE}rankingExplanationContainer`}>
          <div className={`${STYLE_BASE}nameAndRankContainer`}>
            <StyledText
              bold
              type="smallTitle"
            >
              {swolehallaRanking.name}
            </StyledText>
            <StyledText
              bold
              secondary
              type="text"
            >
              {bottomHalf ? `Bottom ${Math.abs(averageNumber-100)}% overall` : `Top ${average}% overall`}
            </StyledText>
          </div>
          <div className={`${STYLE_BASE}inspirationalQuoteContainer`}>
            <StyledText
              type="smallText"
            >
              {getInspirationalQuote(gender)}
            </StyledText>
            <StyledText
              type="smallText"
            >
              - Brocrates
            </StyledText> 
          </div>
        </div>
      </div>
      
      <div className={`${STYLE_BASE}resourceContainer`}>
        <div className={`${STYLE_BASE}swoleExplanationContainer`}>
          <StyledText
            type="smallText"
          >
            {`${muscleGroupInfo.description} your way to Swolehalla a mysteriously swole monk presents you their`}&nbsp;
          </StyledText>
          <a 
            href={muscleGroupInfo.workoutLink} 
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <StyledText className={`${STYLE_BASE}sacredTexts`} type="smallText">
              sacred texts.
            </StyledText>
          </a>
          <StyledText type="smallText">
            &nbsp;
          </StyledText>
          <StyledText type="smallText">
          {`The engravings illuminate the path you must follow to fulfill your destiny.`}
          </StyledText>
          <br />
          <br />
          <StyledText type="smallText">
            {`${SWOLE_MONK_EXPLANATION_ONE}`}
            {`${SWOLE_MONK_EXPLANATION_TWO.replace("$s", formattedCurrentMuscleGroup)}`}
          </StyledText>
        </div>

        <div className={`${STYLE_BASE}productsAndMonkSizeContainer`}>
          <div className={`${STYLE_BASE}productsAndMonkContainer`}>
            <img
              alt="Swole Sales Monk"
              className={`${STYLE_BASE}productMonkImage`}
              src={MONK_IMAGE_URL}
              height={430}
              width={300}
            />
            <div className={`${STYLE_BASE}productScrollContainer`}>
              {
                muscleGroupInfo.products.map((product: IProducts) => {
                  return (
                    <a 
                      className={`${STYLE_BASE}productImageContainer`} 
                      href={product.productUrl}
                      key={product.title}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                    >
                      <img
                        alt={product.title}
                        className={`${STYLE_BASE}productImage`} 
                        data-tip={product.title}
                        src={product.imageUrl} 
                        width="80"
                        height="80"
                      />
                      <ReactTooltip
                        className={"STYLED_TEXT-text"}
                        place="top"
                        multiline={true}
                      />
                    </a>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IProfilePage {
  isMobile: boolean;
}
const ProfilePage = ({isMobile}: IProfilePage): JSX.Element => {
  const [average, setAverage] = React.useState(Number(localStorage.getItem("overallAverage")) || 0);
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [isSigningUp, setIsSigningUp] = React.useState(false);
  const [gender, setGender] = React.useState("Female");
  const [accessToken, setAccessToken] = React.useState(localStorage.getItem("accessToken") || '');
  const [currentMuscleGroup, setCurrentMuscleGroup] = useState('Upper_Body');

  const history = useHistory();

  const {
    quizActionPending
  } = useQuizContext() as IQuizContextValues;

  React.useEffect(() => {
    setAverage(Number(localStorage.getItem("overallAverage")) || average);
    setGender(localStorage.getItem("gender") || "Female");
    setAccessToken(localStorage.getItem("accessToken") || '');
  }, [quizActionPending, accessToken, average]);

  const showModal: boolean = isLoggingIn || isSigningUp;
  const closeModal = isLoggingIn ? () => setIsLoggingIn(false) : () => setIsSigningUp(false);
  const ChildModal = isLoggingIn ? 
    <LoginModal setAccessToken={setAccessToken} setIsLoggingIn={setIsLoggingIn} setAverage={setAverage} /> :
    <SignupModal setAccessToken={setAccessToken} setIsSigningUp={setIsSigningUp} />;

  const username: string = localStorage.getItem("username") || '';
  const swolehallaRanking: IResultsArray = calculateSwolehallaRanking(gender, average);
  const noResultsMessage: string = accessToken ? NO_RESULTS_LOGGED_IN : NO_RESULTS_LOGGED_OUT;

  if (isMobile) {
    return (
      <MobileProfilePage />
    );
  }

  return (
    <div className={`${STYLE_BASE}container`}>
      <Modal children={ChildModal} closeModal={closeModal} showModal={showModal} />
      <div className={`${STYLE_BASE}titleBarContainer`}>
        <StyledText
          className={`${STYLE_BASE}siteTitle`}
          onClickFunction={() => {
            history.push('/');
          }}
          type="bigTitle"
        >
          Swolehalla
        </StyledText>
        <div className={`${STYLE_BASE}accountButtonsContainer`}>
          {!accessToken ?
            <div className={`${STYLE_BASE}notLoggedInContainer`}>
              <div className={`${STYLE_BASE}loginContainer`}>
                <StyledText
                  className={`${STYLE_BASE}logIn`}
                  onClickFunction={() => setIsLoggingIn(true)}
                  type="smallLinkText"
                >
                  Log In
                </StyledText>
                <StyledText
                  className={`${STYLE_BASE}signUp`}
                  onClickFunction={() => setIsSigningUp(true)}
                  type="smallLinkText"
                >
                  Sign up
                </StyledText>
              </div>
              <StyledText
                className={`${STYLE_BASE}about`}
                onClickFunction={() => {
                  history.push('/about');
                }}
                type="smallTitle"
              >
                About
              </StyledText>
            </div>
            :
            <div className={`${STYLE_BASE}loggedInContainer`}>
              <div className={`${STYLE_BASE}logoutContainer`}>
                <StyledText
                  className={`${STYLE_BASE}username`}
                  type="text"
                >
                  {username}
                </StyledText>
                <StyledText
                  className={`${STYLE_BASE}logOut`}
                  onClickFunction={() => {
                    localStorage.clear();
                    history.push('/');
                  }}
                  type="smallLinkText"
                >
                  Log Out
                </StyledText>
              </div>
              <StyledText
                className={`${STYLE_BASE}about`}
                onClickFunction={() => {
                  history.push('/about');
                }}
                type="smallTitle"
              >
                About
              </StyledText>
            </div>
          }
        </div>
      </div>
      {!!average &&
        <div className={`${STYLE_BASE}profileAndImprovementsContainer`}>
          <ProfileSummary
            average={average.toString()}
            currentMuscleGroup={currentMuscleGroup}
            gender={gender}
            swolehallaRanking={swolehallaRanking} 
          />
          <ImproveSummary 
            currentMuscleGroup={currentMuscleGroup}
            setCurrentMuscleGroup={setCurrentMuscleGroup}
          />
        </div>
      }
      {(!quizActionPending && !average) &&
        <StyledText
          className={`${STYLE_BASE}noData`}
          onClickFunction={() => {
            history.push('/');
          }}
          type="smallLinkText"
        >
          {noResultsMessage}
        </StyledText>
      }
    </div>
  );
}

export default ProfilePage;
