import React, { Fragment } from 'react';
import StyledText from 'components/styled-text';
import './mobile-about.scss';
import SocialMenu from 'components/social-menu';
import StyledButton from 'components/styled-button';
import { bottomAboutText, creditsArray, ExtraThanks, OtherSites, PrivacyPolicy, topAboutText } from '../about/about';

const STYLE_BASE = "MOBILE_ABOUT-";

interface ICreditedPerson {
  imageSrc: string;
  name: string;
  personalLink: string;
  title: string;
}

const CreditedPerson = ({imageSrc, name, personalLink, title}: ICreditedPerson): JSX.Element => {
  return (
    <div className={`${STYLE_BASE}personContainer`}>
      <a 
        className={`${STYLE_BASE}personLink`}
        href={personalLink} 
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
      >
        <img
          alt={name}
          className={`${STYLE_BASE}personImage`} 
          src={imageSrc} 
          width="400"
          height="200"
        />
      </a>
      <div className={`${STYLE_BASE}nameAndTitleContainer`}>
        <a 
          className={`${STYLE_BASE}personLink`}
          href={personalLink} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          <StyledText bold className={`${STYLE_BASE}personName`} type="smallTitle">
            {name}
          </StyledText>
        </a>
        <a 
          className={`${STYLE_BASE}personLink`}
          href={personalLink} 
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          <StyledText className={`${STYLE_BASE}personTitle`} secondary type="text">
            {title}
          </StyledText>
        </a>
      </div>
    </div>
  );
}

const MobileAbout = (): JSX.Element => {
  return (
    <Fragment>
      <div className={`${STYLE_BASE}container`}>
        <StyledText className="largeMarginBottom" type="title">
          About Swolehalla
        </StyledText>
        <StyledText className={`largeMarginBottom`} type="text">
          {topAboutText}
        </StyledText>
        <StyledText className={`largeMarginBottom`} type="text">
          {bottomAboutText}
        </StyledText>
      </div>
      <div className={`${STYLE_BASE}creditsContainer`}>
          {creditsArray.map((person) => {
            return (
              <CreditedPerson 
                imageSrc={person.imageSrc}
                name={person.name}
                personalLink={person.personalLink}
                title={person.title}
                key={person.name}
              />
            );
          })}
          <a 
            href = "mailto:ascendingtoswolehalla@gmail.com"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <StyledButton className={`${STYLE_BASE}helpUsImproveButton`} onClick={() => null}>
              Help us improve
            </StyledButton>
          </a>
          <SocialMenu preText="Share this website" />
        </div>
        <OtherSites />
        <ExtraThanks/>
        <PrivacyPolicy />
    </Fragment>
  );
}

export default MobileAbout;
