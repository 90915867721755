import React, { useRef } from 'react';
import './home-page.scss';

import LandingSection from './landing-section';
import FitnessTypes from './fitness-types';
import About from './about';

const STYLE_BASE = "HOME_PAGE-";

interface IHomePage {
  about?: boolean;
  isMobile: boolean;
}
const HomePage = ({about = false, isMobile}: IHomePage): JSX.Element => {
  const aboutRef = useRef<null | HTMLDivElement>(null); 

  const executeScroll = () => {
    if (null !== aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  React.useEffect(() => {
    if (about) {
      executeScroll();
    }
  }, [about]);

  return (
    <div className={`${STYLE_BASE}container`}>
      <LandingSection isMobile={isMobile} />
      <FitnessTypes male isMobile={isMobile} />
      <FitnessTypes male={false} isMobile={isMobile} />
      <div ref={aboutRef}>
        <About isMobile={isMobile} />
      </div>
    </div>
  );
}

export default HomePage;
